<form novalidate #form="ngForm">
  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="row" class="p-3 gap-4">

      <mat-form-field fxFlex  [ftAutoFocus]="true"
                      [appearance]="'outline'"
      >
<!--        TODO-->
        <mat-label>{{(this.isLot ? "settings.lot_number":"settings.serial_number")|translate}}</mat-label>
        <input   required
               [(ngModel)]="model"
               matInput name="code"
        />
      </mat-form-field>

      <mat-form-field fxFlex="30" [appearance]="'outline'" *ngIf="isLot">
        <mat-label>{{"settings.qte"|translate}}</mat-label>
        <input
          [(ngModel)]="qte"
          matInput name="qte"
          type="number" min="1" max="{{maxQte}}"
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions  >

    <button type="button" mat-raised-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{ 'shared.reset'|translate }}</span>
    </button>
    <button type="button" mat-raised-button color="primary" (click)="submitBarCode()"
            [disabled]="form.invalid || (model && model.trim()=='') || (this.isLot ? qte==0 : false)">
      <span>{{ 'shared.add'|translate }}</span>
    </button>
  </div>
</form>
