import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, Subscription} from 'rxjs';
import {PrescriptionService} from "../../../prescription/services/prescription.service";
import {TableColumnDefinition} from "@ft/table";

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
  Articles: any = [];
  subscription: Subscription;
  dataSource: Observable<any[]> = of([]);
  displayedColumns: TableColumnDefinition[] = [
    {
      'label': 'settings.code',
      'key': 'code',
      'sortBy': 'code',
      'type': 'text',
      'is_global_searchable': true
    },
    {
      'label': 'settings.short_desc',
      'key': 'short_desc',
      'sortBy': 'short_desc',
      'type': 'text',
      'is_global_searchable': true
    }
  ];
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20];

  selected = [];
  source$: Observable<any[]>;
  query = {search: '', page: 0, limit: 10};
  paginateHandler = this.handlePaginate.bind(this);

  constructor(public dialogRef: MatDialogRef<ArticleListComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public prescriptionService: PrescriptionService) {
  }

  ngOnInit(): void {
  }

  handleData() {
    this.source$ = this.prescriptionService.getStockArticles(this.query.page, this.query.limit, this.query.search);
  }

  handleSelection() {
    this.dialogRef.close(this.selected);
  }

  handleSearchChange(ev) {
    this.query = ev;
    this.handleData()
  }


  handlePaginate(ev) {
    this.query.page = ev.pageIndex;
    this.handleData();
  }

}
