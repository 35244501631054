import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { projectName } from 'src/app/shared/utils/consts';

@Component({
  selector: 'pr-appointment',
  templateUrl: './ticket-container.component.html',
  styleUrls: ['./ticket-container.component.scss'],
})
export class TicketContainerComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  constructor(
    public translateService: TranslateService,
    private _titleService: Title,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(
      projectName +
        ' - ' +
        this.translateService.instant('appointment.tickets_list')
    );
    this.breakpointObserver
    .observe(['(max-width: 640px)'])
    .subscribe((result) => {
      this.isMobile = result.breakpoints['(max-width: 640px)'] ? true : false;
    });
  }
}
