import {Component, Output, EventEmitter, Input} from '@angular/core';
import {FormComponent} from "@ft/form";
import {assignIn, find, map} from "lodash";
import {BehaviorSubject, of} from "rxjs";
import {TableColumnDefinition} from "@ft/table";
import {ArticleListComponent} from "../../../settings/dialogs/article-list/article-list.component";
import {MEDIUM_DIALOG, SMALL_DIALOG} from "@ft/core";
import {MatDialog} from "@angular/material/dialog";
import {PrescriptionService} from "../../services/prescription.service";

@Component({
  selector: 'pr-prescription-template',
  templateUrl: './prescription-template.component.html',
  styleUrls: ['./prescription-template.component.scss']
})
export class PrescriptionTemplateComponent extends FormComponent {
  @Output() public fieldFileChange = new EventEmitter();
  @Output() public fieldFileDelete = new EventEmitter();
  @Output() public fieldFilePreview = new EventEmitter();
  @Input() public showConsumables = false;

  @Input() public set consumables(data: any) {
        this.consumablesList = data || [];
        this.linesSource$ = of(data);
  }
  @Output() public consumablesChange = new EventEmitter();


  consumablesList = [];
  linesColumns = new BehaviorSubject<TableColumnDefinition []>([
    {
      'label': 'settings.code',
      'key': 'code',
      'sortBy': 'code',
      'type': 'text',
      'editable': false

    },
    {
      'label': 'settings.short_desc',
      'key': 'short_desc',
      'sortBy': 'short_desc',
      'type': 'text',
      'editable': false

    },
    {
      'label': 'settings.qte',
      'key': 'qte',
      'sortBy': 'qte',
      'type': 'number',
      'editable': true,
      class: "editable-input"
    },
    {
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-warn',
          icon: 'mdi-close-circle',
          method: (item, index, ev) => {
            return this.removeLine(index);
          }
        }]
    }
  ]);
  linesSource$ = of([]);

  constructor(public dialog: MatDialog, public prescriptionService: PrescriptionService) {
    super();
  }

  handleFieldFile(ev, blockIdx) {
    this.fieldFileChange.emit(assignIn(ev, {blockIdx}));
  }

  handleFieldFileDelete(ev, blockIdx) {
    this.fieldFileDelete.emit(assignIn(ev, {blockIdx}));
  }

  previewFile(ev) {
    this.fieldFilePreview.emit(ev);
  }


  removeLine(idx: any) {
    this.consumablesList.splice(idx, 1);
    this.linesSource$ = of(this.consumablesList);
  }

  AddConsumable() {
    this.dialog.open(ArticleListComponent, assignIn(SMALL_DIALOG,
      {
        data: {model: null},
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {
      if (data) {
        map(data, (e) => {
          if (!find(this.consumablesList, {"code": e['code']})) {
            this.consumablesList.push({
              "code": e['code'],
              "short_desc": e['short_desc'],
              "qte": 1,
              "external_identifier": e['_id'],
              "has_serial_number":e['has_serial_number'],
              "has_lot_number":e['has_lot_number']
            });
          }
        });
        this.consumablesChange.emit(this.consumablesList)
        this.linesSource$ = of(this.consumablesList);
      }
    });
  }

}
