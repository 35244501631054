import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {get, map, chain, assignIn, isEmpty} from 'lodash';
import {PrescriptionService} from "../../../prescription/services/prescription.service";
import {forkJoin, Subscription} from "rxjs";
import {
  TicketConsumablesBarcodeDialogComponent
} from "../ticket-consumables-barcode-dialog/ticket-consumables-barcode-dialog.component";
import {SMALL_DIALOG} from "@ft/core";
import {TicketService} from "../../services/ticket.service";

@Component({
  selector: 'app-ticket-consumables-dialog',
  templateUrl: './ticket-consumables-dialog.component.html',
  styleUrls: ['./ticket-consumables-dialog.component.scss']
})
export class TicketConsumablesDialogComponent implements OnInit {
  defaultConsumables = []
  ticketId: any = null;
  public subscription: Subscription;
  ticketConsumables = {consumables: []};

  constructor(public dialogRef: MatDialogRef<TicketConsumablesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public prescriptionService: PrescriptionService, public ticketService: TicketService,
              public dialog: MatDialog) {
    this.ticketId = get(data, 'ticket');
    this.subscription = forkJoin([
      this.ticketService.getTicketConsumables(get(data, 'ticket')),
      this.prescriptionService.getPrescriptionConsumables(get(data, 'prescription'))])
      .subscribe(res => {
        this.defaultConsumables = get(res[1], 'consumables', []);
        if (res[0]) {
          this.ticketConsumables = res[0];
        }
        // }


        // Get all ticketConsumables codes
        let currentTicketConsumables = new Set(this.ticketConsumables.consumables.map(item => item.code));

        // Add only items from this.defaultConsumables that are not already in this.ticketConsumables.consumables based on 'id'
        this.defaultConsumables.forEach(item => {
          if (!currentTicketConsumables.has(item.code)) {
            item['qte']=0;
            this.ticketConsumables.consumables.push(item);
          }
        });


      });
  }

  ngOnInit(): void {
  }

  CheckBarcodeColor(item: any) {
    if (item.has_serial_number) return get(item, 'serial_numbers', []).length == item.qte ? 'primary' : 'warn';
    if (item.has_lot_number) return chain(get(item, 'lot_numbers', [])).map('qte').sum().value() == item.qte ? 'primary' : 'warn';
  }

  addBarCode(item: any) {


    this.dialog.open(TicketConsumablesBarcodeDialogComponent, assignIn(SMALL_DIALOG,
      {
        data: {
          isLot: item.has_lot_number,
          maxQte: item.qte - chain(get(item, 'lot_numbers', [])).map('qte').sum().value()
        },
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {
      if (!isEmpty(data)) {
        if (item.has_serial_number) {
          if (!item.serial_numbers) item.serial_numbers = [];
          item.serial_numbers.push(data)
        }
        if (item.has_lot_number) {
          if (!item.lot_numbers) item.lot_numbers = [];
          item.lot_numbers.push(data)
        }
      }
    });
  }

  disableBarCode(item: any) {
    if (item.qte == 0) return true;
    else if (item.has_serial_number) return item.serial_numbers && item.serial_numbers.length == item.qte;
    else {
      return chain(get(item, 'lot_numbers', [])).map('qte').sum().value() == item.qte;
    }
  }

  removeSerie(idx: any, serial_numbers: any) {
    serial_numbers.splice(idx, 1)
  }

  removeLot(idx: any, lot_numbers: any) {
    lot_numbers.splice(idx, 1)
  }

  handleSave() {
    this.subscription = this.ticketService.saveTicketConsumables(assignIn({}, {ticket: this.ticketId}, this.ticketConsumables)).subscribe(data => {
      if (data) {
        this.dialogRef.close()
      }
    })

  }
}
