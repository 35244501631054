import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {has, get} from 'lodash';
import {FtWsService} from '@ft/core';

@Injectable({
  providedIn: 'root'
})
export class PhysicianService {

  constructor(private httpClient: HttpClient, private ws: FtWsService) {
  }

  getPhysicians() {
    return this.httpClient.get<any>(`api/pl/physician/`);
  }

  getPhysiciansByKey(searchKey) {
    return this.httpClient.get<any>(`api/pl/physician/?searchKey=${searchKey}`);
  }

  getPhysician(pk) {
    return this.httpClient.get<any>(`api/pl/physician/${pk}/`);
  }

  savePhysician(model) {
    return this.httpClient.post(`api/pl/physician/${has(model, 'id') ? get(model, 'id') + '/' : ''}`, model);
  }

  deletePhysician(pk) {
    return this.httpClient.delete(`api/pl/physician/${pk}/`);
  }

  allowPhysicianDelete(pk) {
    return this.ws.call(`physician.allow_delete`, {pk});
  }

  getStaffList() {
    return this.httpClient.get('api/staff/staff-form/');
  }


  mergePhysicians(keptPk: any, deletedPks: any) {
    return this.httpClient.put<any>(`api/pl/physician/merge/`, {keptPk, deletedPks});
  }

}
