import {BreakpointObserver} from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, find, first, isEmpty, noop, cloneDeep, assignIn} from 'lodash';
import {TicketService} from '../../services/ticket.service';
import {DateTime} from 'luxon';
import {TIME_UNITS} from 'src/app/shared/utils/consts';
import {
  parseStringToDateTime,
  redirectTo,
  trackByFn,
} from 'src/app/shared/utils/functions';
import KeenSlider, {KeenSliderInstance} from 'keen-slider';
import {
  Subscription,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  from,
  fromEvent,
  map,
  mergeMap,
  toArray, forkJoin,
} from 'rxjs';
import {
  TicketType,
} from '../../ticket.type';
import {UIService} from 'src/app/shared/services/ui.service';
import {
  FileManagerService,
  FtFile,
  VisualizerService,
} from '@ft/file-manager';
import {
  DomSanitizer,
  SafeUrl,
} from '@angular/platform-browser';
import {AppConfigsService, DefaultValuesService, MEDIUM_DIALOG, SMALL_DIALOG} from '@ft/core';
import {BaseCalendarService} from '../../../shared/services/base-calendar.service';
import {FileInfoType} from "../../../shared/types/common.type";
import * as moment from "moment";
import {
  TicketConsumablesDialogComponent
} from "../../dialogs/ticket-consumables-dialog/ticket-consumables-dialog.component";
import {PhysicianSetupComponent} from "../../../settings/dialogs/physician-setup/physician-setup.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'pr-ticket-item',
  templateUrl: './ticket-item.component.html',
  styleUrls: ['./ticket-item.component.scss'],
})
export class TicketItemComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.host-class-mobile') public isMobile = false;
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;

  // Use @ViewChildren to get a query list of all the elements with id="commentTextarea"
  @ViewChildren('commentTextarea') commentTextareas: QueryList<
    ElementRef<HTMLTextAreaElement>
  >;
  public ngBusyItems = {
    proofPassageImages: false,
  };
  public trackByFn = trackByFn('id');
  public slider: KeenSliderInstance = null;
  public md: boolean;
  public lg: boolean;
  public id = '';
  public nextAppointments = [] as TicketType[];
  public appointment = {} as TicketType;
  public uploadedFiles = [];
  public currentFiles = [];
  public commentText: string = ''; // Property to bind to the textarea
  public subscription: Subscription;
  public readFolderSubscription: Subscription;
  private _subs = new Subscription();
  public satisfactionForm: any = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    public breakpointObserver: BreakpointObserver,
    private _ticketService: TicketService,
    private _baseCalendarService: BaseCalendarService,
    private _changeDetector: ChangeDetectorRef,
    private _router: Router,
    private _uiService: UIService,
    private _managerService: FileManagerService,
    private _sanitizer: DomSanitizer,
    private _visualizerService: VisualizerService,
    public defaultValuesService: DefaultValuesService,
    public app_configService: AppConfigsService,
    public dialog: MatDialog
  ) {

  }

  ngAfterViewInit(): void {
    // Subscribe to the changes observable of the query list
    this.commentTextareas.changes.subscribe(() => {
      this.commentTextareas.first.nativeElement.value =
        this.appointment['passage_proof']?.comment || '';
      // Loop through the elements in the query list
      this.commentTextareas.forEach((element) => {
        // element.nativeElement.value = this.appointment['passage_proof']?.comment;
        // Use fromEvent to create an observable from the keyup event
        fromEvent(element.nativeElement, 'input')
          .pipe(
            map((event) => (event.target as HTMLTextAreaElement).value),
            debounceTime(1000),
            distinctUntilChanged()
          )
          .subscribe((value) => {
            this._ticketService
              .update({
                id: this.appointment.id,
                dataAPIAction: 'comment',
                comment: value,
              })
              .subscribe({
                next: (item: TicketType) => {
                  // this._baseCalendarService.setAppointmentNotificationChange$();
                },
              });
          });
      });
    });
  }

  ngOnInit(): void {
    this._subs.add(
      this._activatedRoute.params.subscribe({
        next: (params) => {
          this._subs.add(
            forkJoin([
              this._ticketService.fetch({id: params['id']}),
              this._ticketService.getTicketSatisfactionFormResult(params['id']),
            ]).subscribe({
              next: (data: any): void => {
                this.appointment = data[0];
                this.handleTicketStatus(params['id'])
                this.satisfactionForm = !isEmpty(data[1]) ? first(data[1]) : null;
                this.readFilesFromFolder();
                // Enclose an object within an array.
                this._baseCalendarService.setAppointmentChange$(
                  [this.appointment].flat()
                );
                this._changeDetector.detectChanges();
                this.handleNextTickets();
                this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                  // loop: true,
                  mode: 'snap',
                  slides: {
                    perView: 'auto',
                    spacing: 15,
                  },
                });
              },
            })
          );
        },
        error: (error) => {
          this._uiService.log(error);
        },
      })
    );

    this._subs.add(
      this.breakpointObserver
        .observe([
          '(max-width: 640px)',
          '(max-width: 767px)',
          '(max-width: 940px)',
        ])
        .subscribe((result) => {
          this.isMobile = result.breakpoints['(max-width: 640px)']
            ? true
            : false;
          this.md = result.breakpoints['(max-width: 767px)'] ? true : false;
          this.lg = result.breakpoints['(max-width: 940px)'] ? true : false;
        })
    );
  }

  public handleTicketStatus(pk) {
    if (!this.appointment['ticket_receipt']) {
      this.subscription = this._ticketService.ChangeStatusToReceived([this.appointment]).subscribe(data => {
        this.handleTicketRead(pk);
      });
    } else {
      this.handleTicketRead(pk);
    }
  }

  public handleTicketRead(pk) {
    if (!this.appointment['ticket_read'])
      this.subscription = this._ticketService
        .update({id: pk, dataAPIAction: 'read'}, false)
        .subscribe({
          next: (item: TicketType) => {
            this.appointment = item;
            this._baseCalendarService.setAppointmentNotificationChange$();
          },
        });
  }

  public selectedTabChange() {
    this.slider.update();
  }

  public showMapView() {
    this._baseCalendarService.setMapResizeChange$();
  }

  public toNow(dateVar: any) {
    return this._timeAgo(parseStringToDateTime(dateVar));
  }

  public getLuxonDatetime(str: string) {
    return parseStringToDateTime(str);
  }

  public ticketStatusColor() {
    return find(this.defaultValuesService.getValue('ticketStatusColors'), {
      name: this.appointment.status_histories.at(-1).status,
    });
  }

  public acceptAppointment(id: number) {
    this._ticketAction(id, 'acceptance');
  }

  public rejectAppointment(id: number) {
    this._ticketAction(id, 'rejection');
  }

  public startAppointment(id: number) {
    this._ticketAction(id, 'start');
  }

  public resumeAppointment(id: number) {
    this._ticketAction(id, 'continuation');
  }

  public pauseAppointment(id: number) {
    this._ticketAction(id, 'pause');
  }

  public completeAppointment(id: number) {
    this._ticketAction(id, 'completion');
  }

  public forceComplete(id: number) {
    this._ticketAction(id, 'force-completed');
  }

  public undoCompletionAppointment(id: number) {
    this._ticketAction(id, 'undo-completion');
  }

  public cancelAppointment(id: number) {
    this._ticketAction(id, 'cancellation');
  }

  public uncancelAppointment(id: number) {
    this._ticketAction(id, 'uncancellation');
  }

  public navigateToAppointment(appointment: TicketType) {
    redirectTo(
      this._router,
      '/ticket/custom-busy',
      `appointment/item/${appointment.id}`
    );
  }

  public ngOnDestroy(): void {
    this.slider?.destroy();
    this._subs.unsubscribe();
    this._baseCalendarService.resetAppointmentChange$();
    this.subscription?.unsubscribe();
    this.readFolderSubscription?.unsubscribe();
  }

  public readFilesFromFolder() {
    this.readFolderSubscription?.unsubscribe();
    this.readFolderSubscription = this._managerService
      .readFolder('ticket.models.Ticket', this.appointment.id, null, null, null)
      .subscribe((directory) => {
        this._generateFilesUrl(directory);
      });
  }

  public uploadFiles(files: File[]) {
    const filteredFiles = filter(files, (i) => i instanceof File);
    this.readFolderSubscription?.unsubscribe();
    this.readFolderSubscription = this._managerService
      .readFolder('ticket.models.Ticket', this.appointment.id, null, null, null)
      .pipe(
        mergeMap((currentFolder) =>
          this._managerService.uploadFiles(
            filteredFiles,
            currentFolder?.id,
            null
          )
        )
      )
      .pipe(
        mergeMap((currentFolder) =>
          this._managerService.readFolder(
            'ticket.models.Ticket',
            this.appointment.id,
            null,
            null,
            null
          )
        )
      )
      .subscribe((directory) => {
        this._generateFilesUrl(directory);

        this.uploadedFiles = [];
      });
  }

  public deleteFile(fileId: string) {
    this.readFolderSubscription?.unsubscribe();
    this.readFolderSubscription = this._managerService
      .delete(fileId)
      .subscribe(() => {
        this.readFilesFromFolder();
      });
  }

  public getImageUrl(image): string {
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      return reader.result as string;
    }
    return '';
  }

  public previewFile(fileId: string) {
    this.readFolderSubscription?.unsubscribe();
    this.readFolderSubscription = this._managerService
      .readFolder('ticket.models.Ticket', this.appointment.id, null, null, null)
      .subscribe((directory) => {
        const localizedFile: [number, FtFile[]] =
          directory.visualizedFiles(fileId);
        const file = find(directory.files, {id: fileId});
        this._visualizerService.open({
          data: localizedFile[1],
          index: localizedFile[0],
        });
      });
  }

  public saveComment(event) {
    event.pipe(debounceTime(2000)).subscribe(() => {
    });
  }

  public getDisabledValue() {
    return (
      this.appointment.status_histories?.at(-1)?.status !== 'IN_PROGRESS' &&
      this.appointment.status_histories?.at(-1)?.status !== 'ON_HOLD'
    );
  }

  private _generateFilesUrl(directory) {
    this.ngBusyItems.proofPassageImages = false;
    if (directory.files?.length > 0) {
      this.currentFiles = directory.files;
    } else {
      this.ngBusyItems.proofPassageImages = true;
    }
  }

  private _ticketAction(id: number, action: string) {
    this._subs.add(
      this._ticketService.update({id: id, dataAPIAction: action}).subscribe({
        next: (item: TicketType) => {
          this.appointment = item;
        },
      })
    );
  }

  private _timeAgo(dateTime: DateTime) {
    let units = TIME_UNITS;
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

    let relativeFormatter = new Intl.RelativeTimeFormat('fr', {
      style: 'long',
      numeric: 'always',
    });
    return this._customFormat(
      relativeFormatter.formatToParts(Math.trunc(diff.as(unit)), unit)
    );
  }

  private _customFormat(parts) {
    // Replace the literal parts with custom strings
    parts.forEach((part) => {
      if (part.type === 'literal') {
        if (part.value === 'il y a ') {
          part.value = '-'; // use a minus sign for past times
        } else if (part.value === 'dans ') {
          part.value = '+'; // use a plus sign for future times
        } else {
          part.value = ''; // remove any other literals
        }
      } else if (part.type === 'integer') {
        // Replace the unit part with the first letter
        switch (part.unit) {
          case 'second':
            part.unit = 's';
            break;
          case 'minute':
            part.unit = 'm';
            break;
          case 'hour':
            part.unit = 'h';
            break;
          case 'day':
            part.unit = 'j';
            break;
          case 'week':
            part.unit = 'S';
            break;
          case 'month':
            part.unit = 'm';
            break;
          case 'year':
            part.unit = 'a';
            break;
          default:
            break;
        }
        // Append the unit to the value
        part.value += part.unit;
      }
    });
    // Join the parts into a single string
    return parts.map((part) => part.value).join('');
  }

  private _filterAndSortByDate(array: TicketType[]) {
    // Sort the filtered array by home_visit_date property in ascending order
    let sortedArray = array.sort((a, b) =>
      a.ticket_schedule_histories
        .at(-1)
        .execution_date.localeCompare(
        b.ticket_schedule_histories.at(-1).execution_date
      )
    );

    // Get the first 5 items of the sorted array
    let firstFive = sortedArray.slice(0, 5);

    // add p
    firstFive.forEach((element) => {
      element.day = parseStringToDateTime(
        element.ticket_schedule_histories.at(-1).execution_date
      ).setLocale('fr').day;
      element.month = parseStringToDateTime(
        element.ticket_schedule_histories.at(-1).execution_date
      ).setLocale('fr').monthShort;
      element.year = parseStringToDateTime(
        element.ticket_schedule_histories.at(-1).execution_date
      ).setLocale('fr').year;
    });

    // Return the result
    return firstFive;
  }

  public navigateToSatisfactionForm(pk) {
    this._router
      .navigate(['ticket/satisfaction-form/'], {
        queryParams: {form: pk, read: true},
      })
      .then(noop);
  }

  getFileIcon(file) {
    return file.has_thumbnail ? this._managerService.getFileUrl(file.id, 'thumbnail') : file.icon;
  }

  callPhoneNumber(phoneNumber: string) {
    // Use the tel URI scheme to initiate a phone call
    window.location.href = `tel:${phoneNumber}`;
  }

  handleNextTickets() {
    this._subs.add(
      this._ticketService.fetchAll(null, 'next=true').subscribe({
        next: (items: TicketType[]): void => {
          this.nextAppointments = this._filterAndSortByDate(items);
          this._ticketService
            .ChangeStatusToReceived(items)
            .subscribe(noop);
        },
      })
    );
  }

  formatScheduleDate(d) {
    return moment(d).format(this.app_configService.dateFormat);
  }

  declareConsumable() {
    this.dialog.open(TicketConsumablesDialogComponent, assignIn(SMALL_DIALOG,
      {
        data: {"prescription": this.appointment.prescription.id, "ticket": this.appointment.id},
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {

    });
  }

}
