<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-merge"></mat-icon>
    </button>
  </div>
  <h2>{{ 'settings.merge_physicians' | translate }}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">
  <div [ngBusy]="subscription"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div class="grid grid-cols-2 gap-4 p-6 ">
      <div>
        <div>
          <div fxLayout="row">
            <div fxFlex>
              <mat-form-field fxFlex>
                <mat-label>{{ "settings.physician_to_merge" | translate }}</mat-label>
                <pr-select-search
                  [config]="{key: 'full_name', url: 'api/pl/physician/'}"
                  [(ngModel)]="deleteSelect"
                  name="physician"
                  #physicianModel="ngModel"
                  [multiple]="true"
                >
                </pr-select-search>
                <button
                  matSuffix
                  mat-icon-button
                  color="warn"
                  *ngIf="deleteSelect"
                  [ftValueUnset]="physicianModel"
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
              </mat-form-field>
            </div>

            <button class="mt-1.5 ml-3" [matTooltip]="'settings.add_to_deleted' | translate"
                    type="button"
                    mat-mini-fab
                    color="primary"
                    [disabled]="!deleteSelect"
                    (click)="addItemToDelete(deleteSelect); deleteSelect=null"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-format-align-bottom"></mat-icon>
            </button>
          </div>
          <div class="mt-4 p-4 border rounded"  >
            <h4 class="font-medium">({{ itemsToDelete.length || 0 }}) {{ "settings.selected_items"|translate }}</h4>
            <!--            <ul class=" p-2 max-h-40 overflow-y-auto">-->
            <mat-list *ngIf="itemsToDelete && itemsToDelete.length>0" class="p-2 max-h-40 overflow-y-auto">
              <mat-list-item class="bg-gray-200 rounded mb-4" *ngFor="let p of itemsToDelete trackBy:  let idx=index">
                <span class="flex-1">{{ p.full_name }}</span>
                <button
                  matSuffix
                  mat-icon-button
                  color="warn"
                  (click)="removeToDelete(idx)"
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
              </mat-list-item>
            </mat-list>
            <!--            </ul>-->
          </div>
        </div>
      </div>
      <div>
        <div>
          <mat-form-field fxFlex>
            <mat-label>{{ "settings.physician_to_keep" | translate }}</mat-label>
            <pr-select-search
              [config]="{key: 'full_name', url: 'api/pl/physician/'}"
              [(ngModel)]="keepSelect"
              name="physician2"
              required
              #physician2Model="ngModel"
            >
            </pr-select-search>


            <button
              matSuffix
              mat-icon-button
              color="warn"
              *ngIf="keepSelect"
              [ftValueUnset]="physician2Model"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>


        </div>
        <div class="mt-4 p-4 border rounded">
          <mat-error *ngIf="keepSelect && isToDelete(keepSelect.id)">
            {{ "settings.impossible_to_keep_a_delete_item"|translate }}
          </mat-error>
          <ng-container *ngIf="keepSelect && !isToDelete(keepSelect.id)">
            <div>
              <h4 class="font-medium">{{ "settings.no_selected_element" | translate }}</h4>
              <p><strong>{{ 'patient.last_name'|translate }}: </strong> {{ keepSelect.last_name }}</p>
              <p><strong>{{ 'patient.first_name'|translate }}: </strong> {{ keepSelect.first_name }}</p>
              <p><strong>{{ 'patient.email'|translate }}: </strong> {{ keepSelect?.contact_info?.emails[0] }}</p>
              <p><strong>{{ 'patient.phone_number'|translate }}: </strong> {{ keepSelect?.contact_info?.phone_numbers[0] }}</p>
              <p><strong>{{ 'settings.inpe'|translate }}: </strong> {{ keepSelect.inpe }}</p>
              <p><strong>{{ 'settings.ice'|translate }}: </strong> {{ keepSelect.ice }}</p>
              <p><strong>{{ 'settings.speciality' | translate }}</strong> {{ keepSelect.speciality }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

  </div>

  <div mat-dialog-actions>
    <span fxFlex></span>
    <mat-error *ngIf="mergeError">{{mergeError |translate}}</mat-error>
    <button type="button" mat-flat-button color="primary" (click)="handleMerge()"
            [disabled]="form.invalid || isToDelete(keepSelect?.id) || !itemsToDelete || itemsToDelete.length==0">
      <span>{{ 'settings.merge'|translate }}</span>
    </button>
    <button type="button" mat-flat-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{ 'shared.reset'|translate }}</span>
    </button>
  </div>
</form>
