<mat-toolbar *ngIf="!isMobile">
  <div class="pr-breadcrumb">
    <ol>
      <li routerLink="/" class="hidden md:flex">
        <button type="button" mat-icon-button>
          <mat-icon fontSet="mdi" fontIcon="mdi-home"></mat-icon>
        </button>
      </li>
      <li routerLink="/prescription/list" class="hidden md:flex">
        {{ "prescription.prescription_list" | translate }}
      </li>
      <li>
        {{
          (!prescription?.id
              ? "prescription.new"
              : "prescription.prescription_file"
          ) | translate
        }}
      </li>
    </ol>
    <span class="ft-badge-span"
          *ngIf="prescription?.content?.refundable">{{ "prescription.refundable" | translate }}</span>
  </div>
  <div class="ft-actions">
    <button
      *ngIf="isSpaceInToolbar"
      class="ml-2 cancel-btn"
      type="button"
      mat-flat-button
      color="primary"
      routerLink="/prescription/list"
    >
      {{ "shared.reset" | translate }}
    </button>
    <button
      *ngIf="selectedTabs.includes(2) || prescription?.id"
      class="ml-4"
      type="button"
      mat-flat-button
      color="primary"
      (click)="handleSave($event)"
      (ftAsyncSubmit)="asyncClick($event)"
      [disabled]="
        !prescription?.date || !prescription.patient || !prescription?.content
      "
    >
      {{ "shared.save" | translate }}
    </button>
    <button
      *ngIf="!prescription?.id && !selectedTabs.includes(2)"
      class="ml-4"
      [disabled]="!prescription?.date || !prescription.patient"
      type="button"
      mat-flat-button
      color="primary"
      (click)="handleNextTab()"
    >
      {{ "shared.next" | translate }}
    </button>
    <button
      *ngIf="prescription?.id && !isMobile"
      class="ml-2"
      type="button"
      mat-mini-fab
      color="primary"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'share' }">
        <button
          mat-menu-item
          *ngIf="!prescription?.shared"
          type="button"
          color="primary"
          (click)="handleShare()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-share-variant-outline"
            color="accent"
          ></mat-icon>
          <span> {{ "prescription.share" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'unshare' }">
        <button
          mat-menu-item
          *ngIf="prescription?.shared"
          type="button"
          color="warn"
          (click)="handleCancelShare()"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-vector-polyline-remove" color="warn"></mat-icon>
          <span> {{ "prescription.unshare" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'reactivate' }">
        <button
          mat-menu-item
          *ngIf="prescription?.id && prescription?.status === 'closed'"
          type="button"
          color="primary"
          (click)="handleReactivate()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-file-document"
            color="accent"
          ></mat-icon>
          <span> {{ "prescription.reactivate" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'renew' }">
        <button
          mat-menu-item
          *ngIf="prescription?.id && !prescription?.cancellation_details"
          type="button"
          color="primary"
          (click)="handleRenew()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-clock-plus-outline"
            color="accent"
          ></mat-icon>
          <span>{{ "prescription.renew" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'suspend' }">
        <button
          mat-menu-item
          *ngIf="prescription?.id && !prescription?.suspend_details"
          type="button"
          color="primary"
          (click)="handleSuspend()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-motion-pause-outline"
            color="accent"
          ></mat-icon>
          <span>{{ "prescription.suspend" | translate }}</span>
        </button>
      </ng-template>
      <ng-template
        [ftAcl]="{ resource: 'prescription', action: 'force_conform' }"
      >
        <button
          mat-menu-item
          *ngIf="
            prescription?.id &&
            !isContentConform() &&
            !prescription?.force_conform_details
          "
          type="button"
          color="primary"
          (click)="handleForceConform()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-file-document"
            color="accent"
          ></mat-icon>
          <span>{{ "prescription.force_conform" | translate }}</span>
        </button>
      </ng-template>
      <ng-template
        [ftAcl]="{ resource: 'prescription', action: 'force_close' }"
      >
        <button
          mat-menu-item
          *ngIf="
            prescription?.id &&
            !isCloseAllowed &&
            !prescription?.force_close_details
          "
          type="button"
          color="primary"
          (click)="handleForceClose()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-progress-check"
            color="accent"
          ></mat-icon>
          <span>{{ "prescription.force_close" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'close' }">
        <button
          mat-menu-item
          (click)="closePrescription()"
          [disabled]="prescription?.status === 'closed'"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-progress-check"
            color="accent"
          ></mat-icon>
          <span>{{ "shared.closed" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'sms-message', action: 'send_sms' }">
        <button mat-menu-item (click)="sendSMS()">
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-phone-message"
            color="accent"
          ></mat-icon>
          <span>{{ "shared.sms" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'get' }">
        <button
          mat-menu-item
          [ftPrint]="{
            group: prescription?.content?.name,
            view: '/api/pl/prescription',
            model: prescription['id']
          }"
          (prePrint)="printPrescription()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-printer"
            color="accent"
          ></mat-icon>
          <span>{{ "shared.print" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'cancel' }">
        <button
          mat-menu-item
          *ngIf="
            prescription?.id && !prescription?.cancellation_details?.cancelled
          "
          type="button"
          (click)="handleCancellation()"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-link-variant-off"
            color="warn"
          ></mat-icon>
          <span> {{ "prescription.cancellation" | translate }}</span>
        </button>
      </ng-template>
      <ng-template [ftAcl]="{ resource: 'prescription', action: 'delete' }">
        <button mat-menu-item (click)="removePrescription()">
          <mat-icon fontSet="mdi" fontIcon="mdi-delete" color="warn"></mat-icon>
          <span>{{ "shared.delete" | translate }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>
</mat-toolbar>
<div class="h-full sm:border-t sm:border-b sm:overflow-auto sm:mb-4">
  <mat-tab-group class="no-padding" [(selectedIndex)]="selectTab">
    <mat-tab [label]="'prescription.general_infos' | translate">
      <div class="prescription-first-page w-full">
        <form #form class="p-[16px] w-full">
          <div [ngBusy]="subscription"></div>
          <div class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-2 col-span-12"
              [ftAutoFocus]="true"
            >
              <mat-label>{{
                  "prescription.create_date" | translate
                }}
              </mat-label>
              <input
                matInput
                ftDate
                [matDatepicker]="b_date"
                [placeholder]="'prescription.create_date' | translate"
                required
                [(ngModel)]="prescription.date"
                name="b_date"
              />
              <mat-datepicker-toggle matSuffix [for]="b_date">
                <mat-icon
                  matDatepickerToggleIcon
                  fontSet="mdi"
                  fontIcon="mdi-calendar-today"
                ></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #b_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-5 col-span-12"
            >
              <mat-label>{{
                  "prescription.choose_patient" | translate
                }}
              </mat-label>
              <pr-select-search
                [config]="patientSelectConfig"
                required
                [(ngModel)]="prescription.patient"
                name="patient"
                #patientModel="ngModel"
                (ngModelChange)="handlePatientChange($event)"
              >
              </pr-select-search>
              <button
                matSuffix
                mat-icon-button
                color="warn"
                *ngIf="prescription?.patient"
                [ftValueUnset]="patientModel"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-5 col-span-12"
            >
              <mat-label>{{ "settings.physician" | translate }}</mat-label>
              <pr-select-search
                [config]="physicianSelectConfig"
                [(ngModel)]="prescription.physician"
                name="physician"
                #physicianModel="ngModel"
                [dialogOpener]="opener"
                [disabled]="isDoctor"
              >
              </pr-select-search>
              <button
                matSuffix
                mat-icon-button
                color="primary"
                [ftDialogOpener]="physicianDialogClass"
                [dialogConfig]="{
                  data: { listDefinition: { url: 'api/pl/physician/' } }
                }"
                #opener="ftDialogOpener"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
              </button>
              <button
                matSuffix
                mat-icon-button
                color="warn"
                *ngIf="prescription?.physician"
                [ftValueUnset]="physicianModel"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-2 col-span-12"
            >
              <mat-label>{{ "shared.start_date" | translate }}</mat-label>
              <input
                matInput
                ftDate
                [matDatepicker]="s_date"
                [(ngModel)]="prescription.start_date"
                name="s_date"
                [max]="prescription.end_date"
              />
              <mat-datepicker-toggle matSuffix [for]="s_date">
                <mat-icon
                  matDatepickerToggleIcon
                  fontSet="mdi"
                  fontIcon="mdi-calendar-today"
                ></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #s_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-2 col-span-12"
            >
              <mat-label>{{ "prescription.end_date" | translate }}</mat-label>
              <input
                matInput
                ftDate
                [matDatepicker]="e_date"
                [(ngModel)]="prescription.end_date"
                name="e_date" [min]="prescription.start_date"
              />
              <mat-datepicker-toggle matSuffix [for]="e_date">
                <mat-icon
                  matDatepickerToggleIcon
                  fontSet="mdi"
                  fontIcon="mdi-calendar-today"
                ></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #e_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-3 col-span-12"
            >
              <mat-label>{{ "prescription.priority" | translate }}</mat-label>
              <mat-select
                [(ngModel)]="prescription.priority"
                name="priority"
                [compareWith]="compareCondition"
              >
                <mat-option
                  *ngFor="let p of ['LOW', 'NORMAL', 'HIGH', 'IMMEDIATE']"
                  [value]="p"
                >
                  {{ "prescription." + p | translate }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="prescription.priority"
                matSuffix
                mat-icon-button
                type="button"
                color="warn"
                [matTooltip]="'prescription.priority' | translate"
                (click)="
                  $event.stopImmediatePropagation();
                  prescription.priority = null
                "
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-5 col-span-12"
            >
              <mat-label>{{ "patient.insurance" | translate }}</mat-label>
              <pr-select-search
                [config]="organizationSelectConfig"
                [(ngModel)]="prescription.insurance"
                name="insurance"
                #insuranceModel="ngModel"
              >
              </pr-select-search>
              <button
                matSuffix
                mat-icon-button
                color="warn"
                *ngIf="prescription?.insurance"
                [ftValueUnset]="insuranceModel"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-6 col-span-12"
            >
              <mat-label>{{
                  "patient.intervention_address" | translate
                }}
              </mat-label>
              <textarea
                matInput
                [(ngModel)]="prescription.intervention_address"
                name="intervention_address"
              ></textarea>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-6 col-span-12"
            >
              <mat-label>{{
                  "prescription.intervention_map_address" | translate
                }}
              </mat-label>
              <textarea
                matInput
                [(ngModel)]="prescription.intervention_map_address"
                name="intervention_map_address"
              ></textarea>
            </mat-form-field>
          </div>
          <hr class="ft-sep  w-50% my-8 mx-auto">
          <div *ngIf="!isDoctor" class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-2 col-span-12"
            >
              <mat-label>{{
                  "prescription.payment_deadline" | translate
                }}
              </mat-label>
              <input
                matInput
                ftDate
                [matDatepicker]="deadline_date"
                [(ngModel)]="prescription.payment_infos.deadline"
                name="deadline_date"
              />
              <mat-datepicker-toggle matSuffix [for]="deadline_date">
                <mat-icon
                  matDatepickerToggleIcon
                  fontSet="mdi"
                  fontIcon="mdi-calendar-today"
                ></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #deadline_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-2 col-span-12"
            >
              <mat-label>{{ "prescription.total_amount" | translate }}</mat-label>
              <input
                [(ngModel)]="prescription.payment_infos.total_amount"
                matInput
                name="total_amount"
                [ftNumber]="'currency'"
              />
            </mat-form-field>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="md:col-span-3 col-span-12"
            >
              <mat-label>{{
                  "prescription.payer_type" | translate
                }}
              </mat-label>
              <mat-select
                [(ngModel)]="prescription.payment_infos.payer_type"
                name="payer_type" #payerTypeModel="ngModel"
                [compareWith]="compareCondition"
              >
                <mat-option
                  *ngFor="let t of [{key:'P', label:'prescription.p_payer'},{key:'T', label:'prescription.t_payer'},{key:'O', label:'prescription.o_payer'} ]"
                  [value]="t.key"
                >
                  {{ t.label |translate }}
                </mat-option>
              </mat-select>
              <button matSuffix mat-icon-button color="warn" *ngIf="prescription?.payment_infos?.payer_type"
                      [ftValueUnset]="payerTypeModel">
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field
              [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
              class="md:col-span-5 col-span-12"
            >
              <mat-label>{{ 'prescription.payment_mode_type'| translate }}</mat-label>
              <pr-select-search [config]="{key: 'value', url: 'api/pl/prescription/payment-mode-type/'}"
                                [(ngModel)]="prescription.payment_infos.payment_mode_type" name="payment_mode_type"
                                #paymentModeTypeModel="ngModel"
              >
              </pr-select-search>
              <button matSuffix mat-icon-button color="warn" *ngIf="prescription?.payment_infos?.payment_mode_type"
                      [ftValueUnset]="paymentModeTypeModel">
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field [ngStyle]="isMobile ? {'margin-bottom': '60px'} : {}"
                            [appearance]="!isMobile ? 'outline' : 'standard'"
                            class="col-span-12"
            >
              <mat-label>{{ "shared.note" | translate }}</mat-label>
              <textarea
                [(ngModel)]="prescription.comment"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="10"
                name="comment"
              ></textarea>
            </mat-form-field>
          </div>
        </form>
        <div class="tfu-container p-4" *ngIf="prescription?.id">
          <div class="tfu-inner-container" *ngIf="!tfuPlan">
            <button type="button" class="centred-add-btn" (click)="addFollowUp()">
              <mat-icon
                fontSet="mdi"
                fontIcon="mdi-plus-circle-outline"
              ></mat-icon>
              <span>{{ "prescription.add_follow_up_plan" | translate }}</span>
            </button>

          </div>
          <pr-tfu-plan [tfuPlanId]="tfuPlan.id" *ngIf="tfuPlan"></pr-tfu-plan>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      [label]="'prescription.prescription_details' | translate"
      [disabled]="!prescription?.id && !selectedTabs.includes(1)"
    >
      <div
        class="prescription-confirmation-card-container sm:bg-[#f7f7f7]"
        *ngIf="!prescription.content || medicalTemplateNewSelect"
      >
        <mat-card class="prescription-choice-card">
          <mat-card-header>
            <mat-card-title>{{
                "prescription.choose_model" | translate
              }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="w-full"
            >
              <mat-label>{{
                  "prescription.prescription_model" | translate
                }}
              </mat-label>
              <mat-select
                [(ngModel)]="prescription.content"
                name="prescription_model"
                required
                [compareWith]="compareCondition"
                (ngModelChange)="handleConsumables(prescription.content?.id)"
              >
                <mat-option
                  *ngFor="let eF of prescriptionTemplates"
                  [value]="{
                    id:eF.id,
                    blocks: eF?.blocks,
                    category: eF.category,
                    name: eF.name,
                    refundable: eF.refundable
                  }"
                >
                  {{ eF.name }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="prescriptionTemplate"
                matSuffix
                mat-icon-button
                type="button"
                color="warn"
                [matTooltip]="'shared.clean' | translate"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <button
              [disabled]="!prescription.content"
              class="w-full mt-4"
              type="button"
              mat-flat-button
              color="primary"
              (click)="medicalTemplateNewSelect=false"
            >
              {{ "prescription.confirm_choice" | translate }}
            </button>
          </mat-card-content>
        </mat-card>
      </div>
      <div
        class="prescription-detail main-prescription-detail flex flex-col"
        class="prescription-detail main-prescription-detail flex flex-col"
        *ngIf="prescription?.content && !medicalTemplateNewSelect"
      >
        <div
          *ngIf="
            prescription?.id &&
            !isContentConform() &&
            !prescription?.force_conform_details
          "
          class="compliance-message m-red"
        >
          <div>
            {{ "prescription.non_conform_warning" | translate }}
          </div>
          <ng-template
            [ftAcl]="{ resource: 'prescription', action: 'force_conform' }"
          >
            <button mat-button (click)="handleForceConform()">
              {{ "prescription.force_conform" | translate }}
            </button>
          </ng-template>
        </div>
        <div
          *ngIf="prescription?.id && isContentConform() && !conformOk"
          class="compliance-message m-green"
        >
          <div>{{ "prescription.conform_ok" | translate }}</div>
          <button mat-icon-button (click)="conformOk = true">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
          </button>
        </div>
        <button *ngIf="!prescription?.id && prescription?.content"
                mat-mini-fab
                class="form-edit-btn"
                (click)="medicalTemplateNewSelect = true"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
        </button>
        <div class="flex">

          <pr-prescription-template
            fxFlex
            [(template)]="prescription.content"
            (fieldFileChange)="uploadFieldFiles($event)"
            (fieldFileDelete)="handleFieldFileDelete($event)"
            (fieldFilePreview)="previewFieldFile($event)"
            [(consumables)]="consumablesModel.consumables"
            [showConsumables]="true"
          ></pr-prescription-template>
        </div>
        <div class="flex flex-col p-6 prescription-tutorials">
          <ft-file-manager class="flex"
                           toolbarTitle="{{'prescription.tutorials' | translate}}"
                           *ngIf="prescription?.id"
                           [root]="'prescription'"
                           [context]="tutorialsFileManagerContext"
                           [contextKeys]="['tutorialType']"
                           [rootId]="prescription.id"
          ></ft-file-manager>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      [label]="'prescription.medical_forms' | translate"
      [disabled]="!prescription?.id && !selectedTabs.includes(2)"
    >
      <div
        class="prescription-confirmation-card-container sm:bg-[#f7f7f7]"
        *ngIf="!medicalForms ||medicalForms.length==0|| medicalFormsNewSelect"
      >
        <mat-card class="prescription-choice-card">
          <mat-card-header>
            <mat-card-title>{{
                "prescription.choose_medical_forms" | translate
              }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="w-full"
            >
              <mat-label>
                {{ "prescription.medical_forms" | translate }}
              </mat-label
              >
              <mat-select
                [(ngModel)]="selectedMedicalForms"
                name="medical_forms"
                multiple
                [compareWith]="compareCondition"
              >
                <mat-option
                  *ngFor="let eF of medicalFormsTemplates"
                  [value]="eF"
                >
                  {{ eF.name }}
                </mat-option
                >
              </mat-select>
              <button
                *ngIf="selectedMedicalForms"
                matSuffix
                mat-icon-button
                type="button"
                color="warn"
                [matTooltip]="'shared.clean' | translate"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
            <button
              [disabled]="!selectedMedicalForms"
              class="w-full mt-4"
              type="button"
              mat-flat-button
              color="primary"
              (click)="handleMedicalForms()"
            >
              {{ "prescription.confirm_choice" | translate }}
            </button>
          </mat-card-content>
        </mat-card>
      </div>
      <div
        class="prescription-detail expansion-panels-container"
        *ngIf="medicalForms && medicalForms.length!=0 && !medicalFormsNewSelect"
      >
        <!-- todo complete with forms -->
        <mat-accordion multi>
          <ng-container *ngFor="let f of medicalForms; let idx = index">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ f?.template?.label }}
                </mat-panel-title>
                <mat-panel-description>
                  <button
                    mat-icon-button
                    type="button"
                    color="warn"
                    [matTooltip]="'shared.delete' | translate"
                    (click)="handleMedicalFormDelete(idx)"
                  >
                    <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                  </button>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <pr-prescription-template
                  fxFlex
                  [(template)]="f.template"
                ></pr-prescription-template>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
        <button *ngIf="medicalForms && medicalForms.length!=0"
                mat-mini-fab
                class="form-edit-btn"
                (click)="medicalFormsNewSelect = true"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
        </button>
      </div>
    </mat-tab>
    <mat-tab
      [disabled]="!prescription?.id"
      [label]="'file_manager.toolbar_title' | translate"

    >
      <div class="report-tab grow w-auto m-[16px] flex-col">
        <ft-file-manager
          toolbarTitle="{{'prescription.report' | translate}}"
          [actions]="actions"
          fxFlex
          *ngIf="prescription?.id"
          [root]="'prescription'"
          [context]="fileManagerContext"
          [contextKeys]="['fileType']"
          [rootId]="prescription.id"
        ></ft-file-manager>
      </div>

    </mat-tab>
    <mat-tab *ngIf="prescription?.id" [label]="'shared.comments' | translate">
      <app-comments
        fxFlex
        [docId]="prescription.id"
        [cls]="'prescription'"
      ></app-comments>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template [ftAcl]="{ resource: 'prescription', action: 'unshare' }">
  <button
    mat-mini-fab [ngClass]="{'prescription-cancel-share':prescription?.id}"
    class="action-btn"
    color="warn"
    (click)="handleCancelShare()"
    (ftAsyncSubmit)="asyncClick($event)"
    [disabled]="!prescription?.physician"
    *ngIf="isMobile && (selectedTabs.includes(2) || prescription?.id) && ![3,4].includes(selectTab) && prescription?.id && prescription?.shared "
  >

    <mat-icon fontSet="mdi" fontIcon="mdi-vector-polyline-remove"></mat-icon>
  </button>
</ng-template>
<ng-template [ftAcl]="{ resource: 'prescription', action: 'share' }">
  <button
    mat-mini-fab [ngClass]="{'prescription-share':prescription?.id}"
    class="action-btn"
    color="accent"
    (click)="handleShare()"
    (ftAsyncSubmit)="asyncClick($event)"
    [disabled]="!prescription?.physician"
    *ngIf="isMobile && (selectedTabs.includes(2) || prescription?.id) && ![3,4].includes(selectTab)  && prescription?.id && !prescription?.shared"
  >
    <mat-icon fontSet="mdi" fontIcon="mdi-share-variant-outline"></mat-icon>
  </button>
</ng-template>


<button mat-mini-fab [ngClass]="{'prescription-save':prescription?.id}"
        class="action-btn"
        color="primary"
        (click)="handleSave($event)"
        (ftAsyncSubmit)="asyncClick($event)"
        [disabled]="
    !prescription?.date || !prescription.patient || !prescription?.content
  "
        *ngIf="isMobile && (selectedTabs.includes(2) || prescription?.id) && ![3,4].includes(selectTab)"
>
  <mat-icon fontSet="mdi" fontIcon="mdi-content-save"></mat-icon>
</button>
<button
  mat-mini-fab
  class="action-btn"
  color="primary"
  (click)="handleNextTab()"
  [disabled]="!prescription?.date || !prescription.patient"
  *ngIf="isMobile && !prescription?.id && !selectedTabs.includes(2)"
>
  <mat-icon fontSet="mdi" fontIcon="mdi-page-next-outline"></mat-icon>
</button>
