<mat-toolbar>

  <div *ngIf="!isSearchEnabled" class="toolbar-inner-container">
    <div class="calendar-title-container">
      <input
        class="hidden-input-for-datepicker"
        matInput
        [matDatepicker]="picker"
        placeholder=""
        (dateInput)="onGoToDate($event)"
      />
      <h2 id="calendar-title" [innerHTML]="headerTitle" (click)="picker.open()"
          [ngClass]="{'active': picker.opened}"></h2>
      <mat-datepicker #picker></mat-datepicker>
      <button mat-stroked-button *ngIf="!isMobile" (click)="onToday()">
        <span>{{ "appointment.calendar.today" | translate }}</span>
      </button>
    </div>
    <div class="toolbar-inner-container-separator"></div>

    <button mat-icon-button (click)="enableSearch()">
      <mat-icon
        fontSet="mdi"
        fontIcon="mdi-magnify"
      ></mat-icon>
    </button>

    <div  class="calendar-views-btns-container">
      <button mat-icon-button *ngIf="isMobile" (click)="onToday()">
        <mat-icon
          fontSet="mdi"
          fontIcon="mdi-calendar-today-outline"
        ></mat-icon>
      </button>
      <div *ngIf="!breakpointObserver.isMatched('(max-width: 1200px)')">
        <button
          mat-button
          [ngClass]="{ 'active-view': calendarViewName === 'timeGridDay' }"
          (click)="onDayView()"
        >
          <span>{{ "appointment.calendar.day" | translate }}</span>
        </button>
        <!-- <button
          // TODO week
          mat-button
          [ngClass]="{'active-view': calendarViewName === 'timeGridWeek'}"
          (click)="onWeekView()"
        >
          <span>{{ "appointment.calendar.week" | translate }}</span>
        </button> -->
        <button
          mat-button
          [ngClass]="{ 'active-view': calendarViewName === 'dayGridMonth' }"
          (click)="onMonthView()"
        >
          <span>{{ "appointment.calendar.month" | translate }}</span>
        </button>
        <!-- <button
          // TODO 4 days
          mat-button
          [ngClass]="{'active-view': calendarViewName === 'timeGridFourDay'}"
          (click)="onFourDaysView()"
        >
          <span>{{ "appointment.calendar.four_days" | translate }}</span>
        </button> -->
        <button
          mat-button
          [ngClass]="{ 'active-view': calendarViewName === 'listMonth' }"
          (click)="onListView()"
        >
          <span>{{ "appointment.calendar.list" | translate }}</span>
        </button>
        <button *ngIf="config.map" mat-button (click)="onMapView()">
          <span>{{ "appointment.map" | translate }}</span>
        </button>
      </div>
      <div *ngIf="breakpointObserver.isMatched('(max-width: 1200px)')">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            [ngClass]="{ 'active-view': calendarViewName === 'timeGridDay' }"
            (click)="onDayView()"
          >
            <span>{{ "appointment.calendar.day" | translate }}</span>
          </button>
          <!-- <button
            // TODO week
            mat-menu-item
            [ngClass]="{'active-view': calendarViewName === 'timeGridWeek'}"
            (click)="onWeekView()"
          >
            <span>{{ "appointment.calendar.week" | translate }}</span>
          </button> -->
          <button
            mat-menu-item
            [ngClass]="{ 'active-view': calendarViewName === 'dayGridMonth' }"
            (click)="onMonthView()"
          >
            <span>{{ "appointment.calendar.month" | translate }}</span>
          </button>
          <!-- <button
            // TODO 4 days
            mat-menu-item
            [ngClass]="{'active-view': calendarViewName === 'timeGridFourDay'}"
            (click)="onFourDaysView()"
          >
            <span>{{ "appointment.calendar.four_days" | translate }}</span>
          </button> -->
          <button
            mat-menu-item
            [ngClass]="{ 'active-view': calendarViewName === 'listMonth' }"
            (click)="onListView()"
          >
            <span>{{ "appointment.calendar.list" | translate }}</span>
          </button>
          <button *ngIf="config.map" mat-menu-item (click)="onMapView()">
            <span>{{ "appointment.map" | translate }}</span>
          </button>
        </mat-menu>
      </div>

      <button mat-icon-button class="icon-only" (click)="onPrev()">
        <mat-icon fontSet="mdi" fontIcon="mdi-chevron-left"></mat-icon>
      </button>
      <button mat-icon-button class="icon-only" (click)="onNext()">
        <mat-icon fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
