<!-- todo STOP here-->
<form novalidate #form="ngForm">
  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="column" class="p-3">
      <div fxLayout="column"
           *ngFor="let item of ticketConsumables.consumables"
      >
        <div
          fxLayout="row" fxLayoutGap="6px">
          <mat-form-field fxFlex="70"
                          [appearance]="'outline'"
          >
            <mat-label>{{ "settings.article_code" |translate }}</mat-label>
            <input readonly
                   [(ngModel)]="item.code"
                   matInput [ngModelOptions]="{standalone: true}"
            />
            <button *ngIf="item.has_serial_number || item.has_lot_number"
                    [disabled]="disableBarCode(item)"
                    matSuffix
                    mat-icon-button
                    type="button"

                    [color]="CheckBarcodeColor(item)"
                    [matTooltip]="(item.has_serial_number ? 'appointment.add_serial':'appointment.add_lot') | translate"
                    (click)="addBarCode(item)"
            >
              <mat-icon fontSet="mdi" [fontIcon]="item.has_serial_number ? 'mdi-code-string': 'mdi-barcode'"></mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field fxFlex="30" [appearance]="'outline'">
            <mat-label>{{ "settings.qte"|translate }}</mat-label>

            <input
              [(ngModel)]="item.qte"
              matInput
              ftNumber="float" [fix]="0" min="0" [ngModelOptions]="{standalone: true}"
            />
          </mat-form-field>

        </div>
        <div style="  margin-bottom: 18px" class="consumable-barcode mb-6">
          <mat-chip-list #chipList fxFlex="75" *ngIf="item.has_serial_number"
          >
            <mat-chip
              *ngFor="let e of item.serial_numbers; let i=index"
              [removable]="true"

              (removed)="removeSerie(i,item.serial_numbers)"

              [color]="'primary'">
              {{ e }}
              <mat-icon matChipRemove fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </mat-chip>

          </mat-chip-list>
          <mat-chip-list #chipList fxFlex="75" *ngIf="item.has_lot_number"
          >
            <mat-chip
              *ngFor="let e of item.lot_numbers; let i=index"

              [removable]="true"
              (removed)="removeLot(i,item.lot_numbers)"

              [color]="'primary'">
              ({{ e.qte }})&nbsp;{{ e.code }}
              <mat-icon matChipRemove fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </mat-chip>

          </mat-chip-list>


        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-raised-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{ 'shared.reset'|translate }}</span>
    </button>
    <button type="button" mat-raised-button color="primary" (click)="handleSave()">
      <span>{{ 'shared.save'|translate }}</span>
    </button>
  </div>
</form>


