import {
  Component,
  OnDestroy,
  OnInit,

} from '@angular/core';

// import { appointments } from 'src/app/shared/samples/appointment.sample';

import {SidenavService} from 'src/app/shared/services/sidenav.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {TicketService} from '../../services/ticket.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataApiService} from 'src/app/shared/services/data-api.service';
import {AppConfigsService, DefaultValuesService, FtWsService} from '@ft/core';
import {BaseCalendarComponent} from 'src/app/shared/components/base-calendar/base-calendar.component';
import {TicketType} from '../../ticket.type';
import {BaseCalendarService} from '../../../shared/services/base-calendar.service';
import {PatientAppointmentService} from '../../../patient-appointment/services/patient-appointment.service';
import {get} from "lodash";

@Component({
  selector: 'pr-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent extends BaseCalendarComponent implements OnInit, OnDestroy {
  searchEnabled = false;

  constructor(
    public sidenavService: SidenavService,
    public breakpointObserver: BreakpointObserver,
    public ticketService: TicketService,
    public appoitnmentService: PatientAppointmentService,
    public router: Router,
    public defaultValuesService: DefaultValuesService,
    public app_configService: AppConfigsService,
    public ws: FtWsService,
    public route: ActivatedRoute,
    public baseCalendarService: BaseCalendarService
  ) {
    super(sidenavService, breakpointObserver, ticketService, appoitnmentService, router, defaultValuesService, app_configService, ws, route, baseCalendarService);
    this.apiService = ticketService;

  }

  protected getEventClickNavigationLink(info) {
    return `ticket/item/${info.event.id}`
  }
}
