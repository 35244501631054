import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { get, has } from 'lodash';
import { FtWsService } from '@ft/core';
import { forkJoin, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DataApiService } from '../../shared/services/data-api.service';
import { UIService } from '../../shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService extends DataApiService<any> {
  constructor(
    public httpClient: HttpClient,
    private ws: FtWsService,
    private dialog: MatDialog,
    public uiService: UIService
  ) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/prescription');
  }

  savePrescription(model) {
    return this.httpClient.post(
      `api/pl/prescription/${has(model, 'id') ? get(model, 'id') + '/' : ''}`,
      model
    );
  }

  getPrescriptions(pk) {
    return this.ws.call(`prescription.get_prescription_by_patient`, {
      patient: pk,
    });
  }

  getPrescription(pk) {
    return this.httpClient.get<any>(`api/pl/prescription/${pk}/`);
  }

  deletePrescription(pk) {
    return this.httpClient.delete(`api/pl/prescription/${pk}/`);
  }

  getPatientExternalFiles(pk) {
    return this.ws.call(`prescription.get_patient_external_files`, { pk });
  }

  loadExternalFile(query) {
    return this.ws.call(`prescription.load_external_file`, { query });
  }

  savePrescriptionExamForm(model) {
    return this.httpClient.post(
      `api/pl/prescription-exam-form/${
        has(model, 'id') ? get(model, 'id') + '/' : ''
      }`,
      model
    );
  }

  savePrescriptionSatisfactionForm(model) {
    return this.httpClient.post(
      `api/pl/prescription-satisfaction-form/${
        has(model, 'id') ? get(model, 'id') + '/' : ''
      }`,
      model
    );
  }

  getPrescriptionExamForms(pk) {
    return this.httpClient.get<any>(
      `/api/pl/prescription-exam-form/?prescription=${pk}`
    );
  }

  getStockArticles(page:number, limit:number, search:string|null) {
    return this.httpClient.get<any>(
      `/api/pl/prescription-exam-form/article-list/?page=${page}&limit=${limit}&search=${search}`
    );
  }
  getTemplateConsumables(template:number) {
    return this.httpClient.get<any>(
      `/api/pl/template-consumable/?template=${template}`
    );
  }
  getPrescriptionConsumables(prescription:number) {
    return this.httpClient.get<any>(
      `/api/pl/prescription-consumable/?prescription=${prescription}`
    );
  }

  savePrescriptionConsumables(model:any) {
    return this.httpClient.post(
      `api/pl/prescription-consumable/${
        has(model, 'id') ? get(model, 'id') + '/' : ''
      }`,
      model
    );
  }

  saveTemplateConsumables(model:any) {
    return this.httpClient.post(
      `api/pl/template-consumable/${
        has(model, 'id') ? get(model, 'id') + '/' : ''
      }`,
      model
    );
  }

  getPrescriptionSatisfactionForm(pk) {
    return this.httpClient.get<any>(
      `/api/pl/prescription-satisfaction-form/?prescription=${pk}`
    );
  }

  deletePrescriptionExamForms(pks) {
    return forkJoin(
      pks.map((pk) =>
        this.httpClient.delete(`/api/pl/prescription-exam-form/${pk}/`)
      )
    );
  }

  deletePrescriptionSatisfactionForms(pk) {
    return this.httpClient.delete(
      `/api/pl/prescription-satisfaction-form/${pk}/`
    );
  }

  updatePrescription(pk, data) {
    return this.httpClient.put<any>(`api/pl/prescription/${pk}/`, data);
  }
  sharePrescription(pk, sharedWith) {
    return this.httpClient.put<any>(`api/pl/prescription/share/`, {pk, sharedWith});
  }
  unsharePrescription(pk) {
    return this.httpClient.put<any>(`api/pl/prescription/unshare/`, {pk});
  }
}
