<div class="ft-parent-layout ft-content-margin mat-elevation-z1" fxFlex fxLayout="column">

  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <mat-icon fontSet="mdi" fontIcon="mdi-contacts"></mat-icon>
    </div>
    <h2 translate="settings.physician_list"></h2>
    <span fxFlex></span>
    <ng-template [ftAcl]="{ resource: 'physician', action: 'merge' }">
      <button mat-button class="with-icon" (click)="handleMerge()">
        <mat-icon fontSet="mdi" fontIcon="mdi-merge"></mat-icon>
        <span translate="settings.merge"></span>
      </button>
    </ng-template>
  </mat-toolbar>
  <div fxFlex fxLayout="column" class="ft-content">
    <app-pr-generic-view-table [namespace]="viewSettings?.viewNamespace"
                               [actions]="viewSettings?.actions"
                               [viewSettings]="viewSettings?.viewSettings"
                               [hasPagination]="viewSettings?.hasPagination"
                               [selectable]="viewSettings?.selectable"
                               [query]="viewSettings?.query"
                               [reloadEvent]="reloadEvent"
                               [exportExcel]="true"
    >
    </app-pr-generic-view-table>
  </div>
</div>
