<mat-toolbar class="magnify-container search-header">

    <div class="search-bar basis-full">
        <div class="search-bar-icon search-bar-item back-button md:mr-2 lg:mr-6 sm:mr-0">
            <button mat-icon-button (click)="hideSearch()">
                <mat-icon fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="flex-row flex">
            <div class="search-bar-icon search-bar-item">
                <mat-icon
                        fontSet="mdi"
                        fontIcon="mdi-magnify"
                        [class.mat-primary]="isFocused"
                        class="magnify-icon"
                ></mat-icon>
            </div>
            <div class="search-bar-input search-bar-item">
                <input [ftAutoFocus]="true"
                       type="text"
                       fxFlex
                       [value]="key"
                       [placeholder]="'table.search' | translate"
                       (focus)="isFocused = true"
                       (blur)="isFocused = false"
                       (keyup)="onSearch(handleEventTargetValue($event))"
                />

            </div>
        </div>
    </div>

</mat-toolbar>

<div class="ft-content  search-container">

    <div class="ft-content basis-full result-container">
        <div *ngIf="!result || result.length==0" class="search-empty-result">
            <span translate="shared.no_element_to_show"></span>
        </div>
        <div *ngFor="let ticket of result" class="ticket-item flex-row result-item" (click)="itemClick(ticket)">
            <div class="ticket-time pr-4">
                <span>{{ ticket.formatedDate }}</span>
            </div>
            <div class="ticket-time pr-4  flex-col ">
                <span>{{ ticket.startTime }}</span><span> {{ ticket.endTime }}</span>
            </div>
            <div class="list-event-graphic">
                <!--        <span class="list-event-dot" style="border-color: rgb(255, 217, 217);"></span>-->
                <mat-icon fontSet="mdi" fontIcon="mdi-circle" style="color:{{ticket.bgColor}}"></mat-icon>
            </div>
            <div class="ticket-content flex-col">
                <div class="ticket-reason flex-row">
                    <span>#{{ ticket.external_id }}</span> &nbsp;&nbsp;
                    <span>{{ ticket.template_name |uppercase }}</span> -
                    <span>{{ ticket.reason }}</span>&nbsp;&nbsp;
                </div>
                <div class="ticket-patient flex-row">
                    <span class="mr-1">{{ 'shared.patient'|translate }}</span>:
                    <span>{{ ticket.patient.last_name }}&nbsp;{{ ticket.patient.first_name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
