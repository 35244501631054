import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {PhysicianService} from '../../services/physician.service';
import {SettingsService} from '../../services/settings.service';
import {concat, find, map, reject} from "lodash";


@Component({
  selector: 'app-physician-merge',
  templateUrl: './physician-merge.component.html',
  styleUrls: ['./physician-merge.component.scss']
})
export class PhysicianMergeComponent implements OnInit  {
  subscription: Subscription;
  public searchKey = null;
  public deleteSelect: any = null;
  public itemsToDelete: any = [];
  public keepSelect: any = null;
  public mergeError: any = null;
  @ViewChild('searchPhysicianInput') searchPhysicianInput: ElementRef;

  constructor(public dialogRef: MatDialogRef<PhysicianMergeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public physicianService: PhysicianService,
              public settingsService: SettingsService) {

  }

  ngOnInit(): void {
  }

  handleMerge() {
    this.subscription = this.physicianService.mergePhysicians(this.keepSelect?.id, map(this.itemsToDelete, 'id')).subscribe(data => {
      this.mergeError = null;
      this.dialogRef.close(data);
    }, err => {
      this.mergeError = "settings.physicians_merge_failed";
    });
  }

  public addItemToDelete(items: any) {
    this.itemsToDelete = concat(this.itemsToDelete, reject(items, (e) => {
      return e.id === this.keepSelect?.id && !this.isToDelete(e.id)
    }));
  }

  public removeToDelete(idx: any) {
    this.itemsToDelete.splice(idx, 1);
  }

  public isToDelete(pk: any) {
    return find(this.itemsToDelete, (e) => {
      return e.id == pk
    });
  }

}
