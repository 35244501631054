import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ForceConformDialogComponent} from '../../dialogs/force-conform/force-conform.component';
import {
  AuthService,
  FtWsService,
  MEDIUM_DIALOG,
  SelectConfig,
  SMALL_DIALOG,
} from '@ft/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {projectName} from '../../../shared/utils/consts';
import {PhysicianSetupComponent} from '../../../settings/dialogs/physician-setup/physician-setup.component';
import {PhysicianService} from '../../../settings/services/physician.service';
import {PrescriptionService} from '../../services/prescription.service';
import {PatientService} from '../../../patient/services/patient.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SettingsService} from '../../../settings/services/settings.service';
import {
  FileManagerService,
  FtFile,
  VisualizerService,
} from '@ft/file-manager';
import {mergeMap, switchMap} from 'rxjs/operators';
import {delay, forkJoin, Observable, of, Subscription} from 'rxjs';
import {ExternalFilesDialogComponent} from '../../dialogs/external-files/external-files-dialog.component';
import {
  assign,
  assignIn,
  chain,
  compact,
  concat,
  filter,
  find,
  findIndex,
  first,
  get,
  isEmpty,
  map,
  noop,
} from 'lodash';
import {CancellationDialogComponent} from '../../dialogs/cancellation/cancellation.component';
import {RenewDialogComponent} from '../../dialogs/renew/renew.component';
import {SuspendDialogComponent} from '../../dialogs/suspend/suspend.component';
import {ForceClosureDialogComponent} from '../../dialogs/force-closure/force-closure.component';
import {ClosureDialogComponent} from '../../dialogs/closure/closure.component';
import {SendSmsDialogComponent} from '../../../shared/dialogs/send-sms/send-sms-dialog.component';
import * as moment from 'moment';
import {
  TFUModelType,
  TelephoneFollowUpPlanType,
} from '../../../settings/types/telephone-follow-up.type';
import {TfuPlanService} from '../../../settings/services/tfu-plan.service';
import {
  TfuModelListForPrescriptionComponent
} from '../../../settings/components/tfu/tfu-model-list-for-prescription/tfu-model-list-for-prescription.component';
import {UIService} from "../../../shared/services/ui.service";

@Component({
  selector: 'pr-prescription-content',
  templateUrl: './prescription-content.component.html',
  styleUrls: ['./prescription-content.component.scss'],
})
export class PrescriptionContentComponent implements OnInit, OnDestroy {
  @HostBinding('class.host-class-mobile') public isMobile = false;
  public isSpaceInToolbar = false;
  public isPrescriptionChosen = false;
  public isMedicalFormChosen = false;
  public breakpoints = Breakpoints;
  public prescription: any = {
    date: moment().format('YYYY-MM-DD'),
    priority: 'NORMAL',
    payment_infos: {}
  };
  public tfuPlan: TelephoneFollowUpPlanType | null = null;
  public physicianDialogClass = PhysicianSetupComponent;
  public physicianSelectConfig: SelectConfig = {
    key: 'full_name',
    url: 'api/pl/physician/',
  };
  public patientSelectConfig: SelectConfig = {
    key: 'full_name',
    url: 'api/pl/patient/',
  };
  public organizationSelectConfig: SelectConfig = {
    key: 'name',
    event: 'patient.get_external_insurances',
  };
  public prescriptionTemplate = null;
  public prescriptionTemplates = [];
  public medicalForms = null;
  public deletedMedicalForms = [];
  public medicalFormsTemplates = null;
  public selectedMedicalForms = null;
  public conformOk = false;
  public subscription: Subscription;
  public secondary_subscription: Subscription;
  private _subs = new Subscription();
  private _tempSub = new Subscription();
  public actions = [
    {
      icon: 'mdi-cloud-search',
      method: () => {
        return this.uploadExternalFiles();
      },
      tooltip: this.translateService.instant(
        'prescription.external_files_import'
      ),
    },
  ];
  public acceptedMimes =
    'image/png,image/jpeg,image/jpg,application/pdf,application/gzip';
  public fileManagerContext = new Observable((subscriber) => {
    subscriber.next({fileType: 'Report'});
    subscriber.complete();
  });
  public tutorialsFileManagerContext = new Observable((subscriber) => {
    subscriber.next({tutorialType: 'Link'});
    subscriber.complete();
  });
  public isDoctor = false;
  public selectTab = 0;
  public selectedTabs = [0];
  public medicalFormsNewSelect = false;
  public satisfactionForm = null;
  public filesToUpload = [];
  public isCloseAllowed = true;
  public preventSave = false;
  @ViewChild('tutos', {static: true}) xxElement: ElementRef;
  public medicalTemplateNewSelect = false;
  public consumablesModel: any = [];

  constructor(
    public breakpointObserver: BreakpointObserver,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translateService: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    private _prescriptionService: PrescriptionService,
    public patientService: PatientService,
    protected snackBar: MatSnackBar,
    private physicianService: PhysicianService,
    private settingsService: SettingsService,
    public core_auth: AuthService,
    private _managerService: FileManagerService,
    private _snackBar: MatSnackBar,
    private _translate: TranslateService,
    private _ws: FtWsService,
    private _previewDialog: VisualizerService,
    private _tfuPlanService: TfuPlanService,
    public uiService: UIService
  ) {
  }

  ngOnDestroy(): void {
    this._subs?.unsubscribe();
    this.subscription?.unsubscribe();
    this.secondary_subscription?.unsubscribe();
    this._tempSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 640px)', '(max-width: 960px)'])
      .subscribe((result) => {
        this.isSpaceInToolbar = !result.breakpoints['(max-width: 960px)'];
        this.isMobile = result.breakpoints['(max-width: 640px)'];
      });
    // TODO fix title
    this._titleService.setTitle(
      projectName + ' - ' + this.translateService.instant('prescription.prescription_details')
    );
    this.prescriptionTemplates =
      this.settingsService.prescriptionContentTemplates;
    this.medicalFormsTemplates =
      this.settingsService.prescriptionExamFormTemplates;
    this.isDoctor = this.settingsService.isDoctor();
    this.route.params.subscribe((params: ParamMap) => {
      const pk = get(params, 'id');
      if (pk) {
        this.subscription = this._prescriptionService.getPrescription(pk).subscribe(data => {
          this.prescription = data;
          if (!this.prescription.payment_infos) {
            this.prescription.payment_infos = {};
          }
        });
        this.secondary_subscription = forkJoin([
          this._prescriptionService.getPrescriptionExamForms(pk),
          this._prescriptionService.getPrescriptionSatisfactionForm(pk),
          this._prescriptionService
            .fetch(null, `/api/pl/prescription/follow-up-plan/?prescription_id=${pk}`),
          this._prescriptionService.getPrescriptionConsumables(pk)
        ]).subscribe((data) => {
          this.medicalForms = data[0];
          this.satisfactionForm = first(data[1]);
          this.tfuPlan = data[2];
          if (data[3]) {
            this.consumablesModel = data[3];
          }
        });
      } else {
        this.satisfactionForm = {
          template: this.settingsService.prescriptionSatisfactionFormTemplate,
        };
        this.route.queryParams.subscribe((queryParams) => {
          if (get(queryParams, 'patient')) {
            this.subscription = this.patientService
              .getPatient(get(queryParams, 'patient'))
              .subscribe((data) => {
                this.prescription.patient = data;
                this.prescription.physician = data?.physician;
                this.prescription.insurance = data?.insurance;
                this.prescription.intervention_address = data?.intervention_address;
              });
          }
        });
      }
    });
  }

  compareCondition(o1, o2) {
    return o1 && o2 && (o1.id === o2.id || o1 === o2);
  }

  handlePatientChange(data) {
    if (!this.isDoctor) this.prescription.physician = data.physician;
    this.prescription.insurance = data.insurance;
    this.prescription.intervention_address =
      data.intervention_address || data.address;
    if (this.prescription.insurance) {
      this.prescription.refundable = true;
    }
  }

  isContentConform() {
    if (get(this.prescription, 'id')) {
      return chain(this.prescription?.content?.blocks)
        .map('fields')
        .flatten()
        .filter({type: 'file'})
        .find((e) => {
          return e.required && isEmpty(e.value);
        })
        .isEmpty()
        .value();
    }
  }

  uploadFieldFiles(event) {
    const filteredFiles: File[] = get(event, 'filteredFiles');
    const field = get(event, 'field');
    const block_name = get(event, 'block_name');
    const blockIdx = get(event, 'blockIdx');
    const fieldIdx = get(event, 'fieldIdx');
    const context$ = {field_name: field.name, block_name};
    if (this.prescription?.id) {
      this.handleFieldFilesServerUpload(
        filteredFiles,
        field,
        block_name,
        blockIdx,
        fieldIdx,
        context$
      );
    } else {
      this.filesToUpload.push({
        filteredFiles,
        field,
        block_name,
        blockIdx,
        fieldIdx,
        context$,
      });
      this.prescription.content.blocks[blockIdx].fields[fieldIdx].value = {
        file_name: get(filteredFiles, '0.name'),
      };
    }
  }

  handleFieldFilesServerUpload(
    filteredFiles,
    field,
    block_name,
    blockIdx,
    fieldIdx,
    context$
  ) {
    this.subscription = this._managerService
      .readFolder(
        'prescription.models.Prescription',
        this.prescription?.id,
        null,
        ['field_name', 'block_name'],
        context$
      )
      .pipe(
        mergeMap((currentFolder) =>
          this._managerService.uploadFiles(
            filteredFiles,
            currentFolder?.id,
            context$
          )
        )
      )
      .subscribe(
        (data) => {
          this._managerService
            .readFolder(
              'prescription.models.Prescription',
              this.prescription?.id,
              data.id,
              ['field_name', 'block_name'],
              context$
            )
            .subscribe((d) => {
              this.prescription.content.blocks[blockIdx].fields[
                fieldIdx
                ].value = {
                file_name: get(filteredFiles, '0.name'),
                file_id: (chain(d.files).find({context: context$}) as any)
                  .get('id')
                  .value(),
              };
              this._prescriptionService
                .updatePrescription(this.prescription?.id, {
                  content: this.prescription?.content,
                })
                .subscribe(noop);
              this.subscription.unsubscribe();
            });
        },
        (e) => {
          this._snackBar.open(
            this._translate.instant('file_manager.upload_error')
          );
        }
      );
  }

  handleFieldFileDelete(ev) {
    const fileId = get(ev, 'fileId');
    const blockIdx = get(ev, 'blockIdx');
    const fieldIdx = get(ev, 'fieldIdx');
    if (this.prescription?.id) {
      this._managerService.erase(fileId).subscribe((_) => {
        this.prescription.content.blocks[blockIdx].fields[fieldIdx].value =
          null;
        this._prescriptionService
          .updatePrescription(this.prescription?.id, {
            content: this.prescription?.content,
          })
          .subscribe(noop);
      });
    } else {
      const idx = findIndex(this.filesToUpload, {
        fieldIdx: fieldIdx,
        blockIdx: blockIdx,
      });
      this.filesToUpload.splice(idx, 1);
      this.prescription.content.blocks[blockIdx].fields[fieldIdx].value = null;
    }
  }

  previewFieldFile(event) {
    const fileId = get(event, 'fileId');
    if (fileId) {
      this._managerService
        .readFolder(
          'prescription.models.Prescription',
          this.prescription?.id,
          null,
          ['field_name', 'block_name'],
          null
        )
        .subscribe((data) => {
          const res: [number, FtFile[]] = data.visualizedFiles(fileId);
          const file = find(data.files, {id: fileId});

          if (file.type) {
            file.is_converted
              ? this._previewDialog.open({
                data: res[1],
                index: res[0],
              })
              : this._snackBar.open(
                this._translate.instant('file_manager.converted_msg')
              );
          } else {
            this._managerService
              .download(file.id, file.name)
              .subscribe((download) => download());
          }
        });
    }
  }

  handleFieldsUploads() {
    forkJoin(
      this.filesToUpload.map((f) =>
        this._managerService
          .readFolder(
            'prescription.models.Prescription',
            this.prescription?.id,
            null,
            [],
            f.context$
          )
          .pipe(
            mergeMap((currentFolder) =>
              this._managerService.uploadFiles(
                f.filteredFiles,
                currentFolder?.id,
                f.context$
              )
            ),
            mergeMap((currentFolderAfterUpload) =>
              this._managerService.readFolder(
                'prescription.models.Prescription',
                this.prescription?.id,
                currentFolderAfterUpload.id,
                ['field_name', 'block_name'],
                f.context$
              )
            ),
            mergeMap((currentFolderWithFiles) => {
              this.prescription.content.blocks[f.blockIdx].fields[
                f.fieldIdx
                ].value = {
                file_name: get(f.filteredFiles, '0.name'),
                file_id: (
                  chain(currentFolderWithFiles.files).find({
                    context: f.context$,
                  }) as any
                )
                  .get('id')
                  .value(),
              };
              return of({});
            }),
            mergeMap(({}) =>
              this._prescriptionService.updatePrescription(
                this.prescription?.id,
                {content: this.prescription?.content}
              )
            )
          )
      )
    ).subscribe(noop);
  }

  uploadExternalFiles() {
    this.dialog
      .open(
        ExternalFilesDialogComponent,
        assignIn(MEDIUM_DIALOG, {
          data: {
            patient: get(this.prescription, 'patient.external_identifier'),
          },
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (!isEmpty(data)) {
          const items = filter(data, (i) =>
            this.acceptedMimes.includes(i.mime)
          );
          for (const item of items) {
            this._prescriptionService
              .loadExternalFile({
                pk: item.id,
                file_name: item.name,
                prescription_id: this.prescription.id,
              })
              .subscribe(
                (res: any) => {
                  noop();
                },
                (err) => {
                  this.snackBar.open(
                    this.translateService.instant('shared.upload_file_error'),
                    null,
                    {
                      duration: 2000,
                      horizontalPosition: 'center',
                      verticalPosition: 'top',
                    }
                  );
                }
              );
          }
        }
      });
  }

  handleSave(ev) {
    if (!this.preventSave) {
      if (this.isDoctor) {
        this.prescription.physician = this.core_auth.getStaff();
        this.save();
      } else {
        this.save();
      }
    }
  }

  save() {
    this.preventSave = true;
    this._prescriptionService
      .savePrescription(this.prescription)
      .subscribe(p => {
          this.snackBar.open(
            this.translateService.instant('shared.save_success')
          );
          if (!this.prescription.id) {
            this.router
              .navigate(['prescription/content/', p['id']], {
                replaceUrl: true,
              })
              .then(noop);
          }
          this.preventSave = false;
          this.prescription = p;
          forkJoin([
            this.deletedMedicalForms.length > 0
              ? this._prescriptionService.deletePrescriptionExamForms(
                this.deletedMedicalForms
              )
              : of({}),
            this.satisfactionForm.template
              ? this._prescriptionService.savePrescriptionSatisfactionForm(
                assign(this.satisfactionForm, {
                  prescription: this.prescription.id,
                })
              )
              : of({}),
            this.medicalForms && this.medicalForms.length > 0
              ? forkJoin(
                this.medicalForms.map((f) =>
                  this._prescriptionService.savePrescriptionExamForm(
                    assign(f, {prescription: this.prescription.id})
                  )
                )
              )
              : of([]),

            this._prescriptionService.savePrescriptionConsumables(assignIn({}, {prescription: this.prescription.id}, this.consumablesModel))

          ]).subscribe(
            ([
               deletedRes,
               satisfactionForms,
               medicalForms,
               consumablesObject
             ]): any => {
              this.medicalForms = medicalForms;
              this.satisfactionForm = satisfactionForms;
              this.deletedMedicalForms = [];
              this.consumablesModel = consumablesObject;
              this.handleFieldsUploads();
            },
            (err) => {
              this.preventSave = false;
            }
          );
        }
      );
  }

  handleNextTab() {
    this.selectTab = this.selectTab >= 4 ? 0 : this.selectTab + 1;
    if (!this.selectedTabs.includes(this.selectTab)) {
      this.selectedTabs.push(this.selectTab);
    }
  }

  handleMedicalForms() {
    this.medicalForms = compact(
      concat(
        this.medicalForms,
        map(this.selectedMedicalForms, (e) => {
          return {template: e, prescription: get(this.prescription, 'id')};
        })
      )
    );
    this.selectedMedicalForms = null;
    this.medicalFormsNewSelect = false;
  }

  handleMedicalFormDelete(idx) {
    if (this.medicalForms[idx].id) {
      this.deletedMedicalForms.push(this.medicalForms[idx].id);
    }
    this.medicalForms.splice(idx, 1);
  }

  handleCancellation() {
    this.dialog
      .open(
        CancellationDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {},
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this._prescriptionService
            .updatePrescription(this.prescription.id, {
              cancellation_details: data,
            })
            .subscribe((res) => {
              if (res) {
                this.prescription = assignIn(this.prescription, {
                  cancellation_details: data,
                });
              }
            });
        }
      });
  }

  handleReactivate() {
    this._prescriptionService
      .updatePrescription(this.prescription.id, {
        close_details: null,
        status: 'entered',
      })
      .subscribe((data) => {
        if (data) {
          this.prescription = assignIn(this.prescription, {
            status: 'entered',
          });
        }
      });
  }

  handleRenew() {
    this.dialog
      .open(
        RenewDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {},
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this._prescriptionService
            .updatePrescription(this.prescription.id, {
              renew_details: concat(
                this.prescription.renew_details || [],
                data
              ),
            })
            .subscribe((res) => {
              if (res) {
                this.prescription = assignIn(this.prescription, {
                  renew_details: concat(
                    this.prescription.renew_details || [],
                    data
                  ),
                });
              }
            });
        }
      });
  }

  handleSuspend() {
    this.dialog
      .open(
        SuspendDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {},
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this._prescriptionService
            .updatePrescription(this.prescription.id, {suspend_details: data})
            .subscribe((res) => {
              if (res) {
                this.prescription = assignIn(this.prescription, {
                  suspend_details: data,
                });
              }
            });
        }
      });
  }

  handleForceConform() {
    this.dialog
      .open(
        ForceConformDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {},
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this._prescriptionService
            .updatePrescription(this.prescription.id, {
              force_conform_details: data,
            })
            .subscribe((res) => {
              if (res) {
                this.prescription = assignIn(this.prescription, {
                  force_conform_details: data,
                });
              }
            });
        }
      });
  }

  getLastRenewDetails() {
    return get(this.prescription, 'renew_details', []).slice(-1);
  }

  handleForceClose() {
    this.dialog
      .open(
        ForceClosureDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {},
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this._prescriptionService
            .updatePrescription(this.prescription.id, {
              force_close_details: data,
            })
            .subscribe((res) => {
              if (res) {
                this.prescription = assignIn(this.prescription, {
                  force_close_details: data,
                });
              }
            });
        }
      });
  }

  closePrescription() {
    if (get(this.prescription, 'content.report_count') > 0) {
      this._managerService
        .readFolder(
          'prescription.models.Prescription',
          this.prescription?.id,
          null,
          ['fileType'],
          null
        )
        .subscribe((data) => {
          if (
            data.files.length < get(this.prescription, 'content.report_count')
          ) {
            this.snackBar.open(
              this.translateService.instant(
                'prescription.close_not_allowed_warning'
              )
            );
            this.isCloseAllowed = false;
          } else {
            this.handleClose();
          }
        });
    } else {
      this.handleClose();
    }
    // return get(this.prescription, 'content.report_count') < 0;
  }

  handleClose() {
    this.dialog
      .open(
        ClosureDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {},
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this._prescriptionService
            .updatePrescription(this.prescription.id, {
              close_details: data,
              status: 'closed',
            })
            .subscribe((res) => {
              if (res) {
                this.prescription = assignIn(this.prescription, data, {
                  status: 'closed',
                });
              }
            });
        }
      });
  }

  sendSMS() {
    this.dialog
      .open(
        SendSmsDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {
            patient: get(this.prescription, 'patient'),
            physician: get(this.prescription, 'physician'),
          },
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe(noop);
  }

  printPrescription() {
  }

  removePrescription() {
    this.subscription = this._prescriptionService
      .deletePrescription(this.prescription.id)
      .subscribe({
        next: (data) => {
          this.router.navigate(['/prescription/list']).then(noop);
        }, error: (error) => {
          this.uiService.openSnackBar('shared.delete_error');
        }
      });
  }

  asyncClick(ev) {
    this.subscription = of([]).pipe(delay(5000)).subscribe();
  }

  public addFollowUp() {
    let dialogRef = this.dialog.open(TfuModelListForPrescriptionComponent, {
      panelClass: 'big-dialog',
      data: {
        question: 'settings.delete_follow_up_step_question',
      },
    });
    this._tempSub?.unsubscribe();
    this._tempSub = dialogRef.afterClosed().subscribe({
      next: (data: TFUModelType) => {
        if (data) {
          // this.tfuPlan = data;
          this._subs.add(
            this._tfuPlanService
              .create({
                title: data.title,
                status: 'IN_PROGRESS',
                items: data.items,
                prescription: this.prescription.id,
              })
              .subscribe({
                next: (item: TelephoneFollowUpPlanType): void => {
                  this.tfuPlan = item;
                },
              })
          );
        }
      },
      error: (error: Error) => {
        console.error(error);
      },
    });
  }

  handleConsumables(ev: any) {
    this._prescriptionService.getTemplateConsumables(ev).subscribe(data => {
      if (data) {
        this.consumablesModel.consumables = data.consumables;
      }
    });
  }

  handleShare() {
    this._prescriptionService.sharePrescription(this.prescription.id, this.prescription?.physician?.id).subscribe(data => {
      if (data) {
        this.prescription.shared= true;
        this.uiService.openSnackBar('prescription.share_success', this.isMobile ? 'top' : 'bottom');

      }
    });
  }

  handleCancelShare() {
    this._prescriptionService.unsharePrescription(this.prescription.id).subscribe(data => {
      if (data) {
        this.prescription.shared= false;
        this.uiService.openSnackBar('prescription.unshare_success', this.isMobile ? 'top' : 'bottom');

      }
    });
  }
}
