import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {find, get} from "lodash";
import {DefaultValuesService} from "@ft/core";
import {TicketService} from "../../../ticket/services/ticket.service";
import {noop, Subject} from "rxjs";
import {Router} from "@angular/router";
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';

@Component({
  selector: 'pr-calendar-search',
  templateUrl: './calendar-search.component.html',
  styleUrls: ['./calendar-search.component.scss']
})
export class CalendarSearchComponent implements OnInit {
  public key: string = '';
  public isFocused: boolean = false;
  // @Output() public searchChange = new EventEmitter<string>();
  @Output() public closeSearch = new EventEmitter<boolean>();
  result: any = [];
  multipleMonths = false;
  private searchSubject = new Subject<string>();

  constructor(public router: Router, public defaultValuesService: DefaultValuesService, public ticketService: TicketService
  ) {
    this.searchSubject.pipe(
      debounceTime(300), // Wait 300ms after the last event before calling API
      distinctUntilChanged(), // Ignore if the new value is the same as the previous one
      switchMap(event => event ? this.ticketService.searchTickets(event) : [])
    ).subscribe(data => {
      this.result = this.formatItems(data);
    });
  }

  ngOnInit(): void {
    this.onSearch(null);
  }

  public onSearch(event: any): void {
    if (!event || event.trim() === '') {
      this.result = [];
    } else {
      this.searchSubject.next(event);
    }
  }


  public cancelSearch(): void {
    this.key = '';
    this.onSearch('');
  }

  public handleEventTargetValue($event: Event) {
    return ($event.target as HTMLInputElement).value;
  }

  public hideSearch(): void {
    this.closeSearch.emit(true);
  }

  formatItems(items: any[]): any[] {
    return items.map(item => {
      return {
        id: item.id,
        external_id: item.external_id,
        formatedDate: item.execution_date,
        startTime: `${item.start_time}`,
        endTime: `${item.end_time}`,
        template_name: get(item, 'prescription.template_name'),
        reason: item.reason,
        patient: item.patient,
        bgColor: this.statusColor(item.status)?.bgColor
      };

    });
  }

  public statusColor(s: string) {
    return find(this.defaultValuesService.getValue('ticketStatusColors'), {
      name: s,
    });
  }

  itemClick(item) {
    this.router
      .navigate([`ticket/item/${item.id}`])
      .then(noop);
  }

}
