import {Component} from '@angular/core';
import {FormSetupComponent, FormSetupService} from '@ft/form';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PhysicianSetupComponent} from "../../dialogs/physician-setup/physician-setup.component";
import {assignIn, map, find, get, findIndex} from "lodash";
import {MEDIUM_DIALOG} from "@ft/core";
import {TableColumnDefinition} from "@ft/table";
import {MatDialog} from "@angular/material/dialog";
import {ArticleListComponent} from "../../dialogs/article-list/article-list.component";
import {BehaviorSubject} from "rxjs";
import {of} from 'rxjs';
import {PrescriptionService} from "../../../prescription/services/prescription.service";
import {UIService} from "../../../shared/services/ui.service";

@Component({
  selector: 'app-prescription-forms',
  templateUrl: './prescription-forms.component.html',
  styleUrls: ['./prescription-forms.component.scss']
})
export class PrescriptionFormsComponent extends FormSetupComponent {
  public fieldTypes = [
    'checkbox',
    'radio-button',
    'text',
    'long-text',
    'number',
    'date',
    'title',
    'paragraph',
    'list',
    'image',
    'table-layout',
    'formula',
    'file'
  ];
  linesColumns = new BehaviorSubject<TableColumnDefinition []>([
    {
      'label': 'settings.code',
      'key': 'code',
      'sortBy': 'code',
      'type': 'text',
      'editable': false

    },
    {
      'label': 'settings.short_desc',
      'key': 'short_desc',
      'sortBy': 'short_desc',
      'type': 'text',
      'editable': false

    },
    {
      'label': 'settings.qte',
      'key': 'qte',
      'sortBy': 'qte',
      'type': 'number',
      'editable': true,
      class: "editable-input"
    },
    {
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-warn',
          icon: 'mdi-close-circle',
          method: (item, index, ev) => {
            return this.removeLine(index);
          }
        }]
    }
  ]);
  linesSource$ = of([]);
  consumablesModel = {"consumables": []};

  constructor(
    translateService: TranslateService,
    formSetupService: FormSetupService,
    _route: ActivatedRoute,
    public localRoute: ActivatedRoute,
    _router: Router,
    public localRouter: Router,
    snackBar: MatSnackBar,
    public dialog: MatDialog,
    public prescriptionService: PrescriptionService,
    private uiService: UIService
  ) {
    super(translateService, formSetupService, _route, _router, snackBar);
  }

  public ngOnInit(): void {
    super.ngOnInit();


    this.localRoute.queryParams.subscribe(params => {
      if (get(params, 'id')) {
        this.loadTemplateConsumable(get(params, 'id'));
      }
    })

  }

  compareCondition(o1, o2) {
    return o1 && o2 && o1 === o2;
  }

  AddConsumable() {
    this.dialog.open(ArticleListComponent, assignIn(MEDIUM_DIALOG,
      {
        data: {model: null},
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {
      if (data) {
        map(data, (e) => {
          if (!find(this.consumablesModel.consumables, {"code": e['code']})) {
            this.consumablesModel.consumables.push({
              "code": e['code'],
              "short_desc": e['short_desc'],
              "qte": 1,
              "external_identifier": e['_id']
            });
          }
        });
        this.linesSource$ = of(this.consumablesModel.consumables);
      }
    });
  }

  loadTemplateConsumable(templateId: number) {
    this.prescriptionService.getTemplateConsumables(templateId).subscribe(data => {
      if (data){
         this.consumablesModel = data;
         this.linesSource$ = of(data.consumables);
      }
    });
  }

  saveTemplateConsumables(templateId: number) {
    if (this.model.category == 'PRESCRIPTION_TEMPLATE') {
      this.prescriptionService.saveTemplateConsumables(assignIn({}, {template: templateId}, this.consumablesModel)).subscribe((data: any) => {
        this.consumablesModel = data;
        this.linesSource$ = of(data.consumables);
      });
    }

  }

  public save(): void {
    this.formSetupService.saveFormTemplate(this.model).subscribe(data => {
      this.model = data;
      let idx = findIndex(this.items, {id: get(data, 'id')});

      if (idx === -1) {
        this.items.push(data);
        this.currentTemplate = get(data, 'id');
        this.busy = this.navigate(this.currentTemplate);
      } else {
        this.items[idx] = data;
      }
      this.saveTemplateConsumables(get(data, 'id'));
      this.uiService.openSnackBar('shared.save_success');
    });
  }

  private navigate(id?: number | any, copy?: number) {
    return this.localRouter.navigate([], {
      queryParams: {id, copy},
      relativeTo: this.localRoute,
      queryParamsHandling: 'merge',
    });
  }

  removeLine(idx: any) {
    this.consumablesModel.consumables.splice(idx, 1);
    this.linesSource$ = of(this.consumablesModel.consumables);
  }


}


