import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get, map, chain} from 'lodash';
import {PrescriptionService} from "../../../prescription/services/prescription.service";
import {forkJoin, Subscription} from "rxjs";

@Component({
  selector: 'app-ticket-consumables-barcode-dialog',
  templateUrl: './ticket-consumables-barcode-dialog.component.html',
  styleUrls: ['./ticket-consumables-barcode-dialog.component.scss']
})
export class TicketConsumablesBarcodeDialogComponent implements OnInit {
  model: any = null;
  isLot: any = false;
  qte: any = 1;
  maxQte: any = 0;

  constructor(public dialogRef: MatDialogRef<TicketConsumablesBarcodeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isLot = get(data, 'isLot',false);
    this.maxQte = get(data, 'maxQte',0);
  }

  ngOnInit(): void {
  }

  submitBarCode() {
    this.dialogRef.close(!this.isLot ? this.model.trim(): {"code":this.model.trim(), "qte": this.qte});
  }

}
