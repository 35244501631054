<!-- <full-calendar [options]="calendarOptions"></full-calendar> -->

<!-- <div class="map-outer-container">
  <pr-map></pr-map>
</div>  -->
<mat-drawer-container class="content-container" [ngBusy]="subscription">
  <mat-drawer class="map-outer-container" #drawer mode="over">
    <div class="map-inner-container">
      <mat-toolbar class="map-header">
        <div class="map-title">{{ "appointment.map" | translate }}</div>
        <div class="map-close-container">
          <button
            mat-icon-button
            class="icon-only map-close"
            (click)="drawer.toggle()"
          >
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <pr-map class="map-content"></pr-map>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <!-- Main content -->
    <!-- <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button> -->

    <div class="calendar-container">
      <pr-calendar-header *ngIf="!searchEnabled"
                          (next)="next()"
                          (dayView)="dayView()"
                          (weekView)="weekView()"
                          (monthView)="monthView()"
                          (fourDaysView)="fourDaysView()"
                          (listView)="listView()"
                          (mapView)="mapView()"
                          (prev)="prev()"
                          (today)="today()"
                          (createEvent)="createEvent()"
                          (goToDate)="goToDate($event)"
                          [headerTitle]="calendarHeaderTitle"
                          [activeView]="emitCalendarView"
                          [(searchEnabled)]="searchEnabled"
      ></pr-calendar-header>

      <section  class="calendar">
        <div   id="full-calendar" #calendar></div>
      </section>
      <div *ngIf="searchEnabled" class="search-container">
        <pr-calendar-search
          (closeSearch)="searchEnabled=false"></pr-calendar-search>
      </div>
    </div>


  </mat-drawer-content>
</mat-drawer-container>
