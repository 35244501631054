<!--<ft-form-setup [nameValidationUrl]="'api/form-template/'"
               [customTemplate]="FormTemplateCustomProperties"></ft-form-setup>

-->
<!--<ng-template let-model="model" #FormTemplateCustomProperties>-->

<ng-template let-model="model" #customTemplateContent>
  <div fxLayout="column" fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutGap="6px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'form.type' | translate }}</mat-label>
        <mat-select [disabled]="this.consumablesModel.consumables && this.consumablesModel?.consumables.length>0"
                    [(ngModel)]="model.category"
                    [compareWith]="compareCondition"

        >
          <mat-option
            *ngFor="let t of ['PRESCRIPTION_TEMPLATE','PRESCRIPTION_EXAM_FORM','PRESCRIPTION_SATISFACTION_FORM']"
            [value]="t">
            {{ "settings." + t | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field fxFlex *ngIf="model.category=='PRESCRIPTION_TEMPLATE'">
        <input matInput type="number" required name="report_count"
               placeholder="{{'prescription.report_count'|translate}}"
               [(ngModel)]="model.report_count"/>
      </mat-form-field>
    </div>
    <div class=" flex flex-row gap-4">
      <mat-slide-toggle *ngIf="['PRESCRIPTION_TEMPLATE','PRESCRIPTION_SATISFACTION_FORM'].includes(model.category)"
                        [(ngModel)]="model.is_default"
                        name="is_default">
        <span fxFlex>{{ 'settings.is_default' | translate }}</span>
      </mat-slide-toggle>
      <mat-slide-toggle *ngIf="['PRESCRIPTION_TEMPLATE'].includes(model.category)"
                        [(ngModel)]="model.refundable"
                        name="refundable">
        <span fxFlex>{{ 'prescription.refundable' | translate }}</span>
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>


<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxLayout="column">
  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <button mat-icon-button>
        <mat-icon fontSet="mdi" fontIcon="mdi-view-dashboard"></mat-icon>
      </button>
    </div>
    <h2 translate="form.form_setup"></h2>
    <span fxFlex></span>
    <button
      mat-button
      class="with-icon"
      *ngIf="!model?.id"
      accept=".json"
      ngfSelect
      multiple="1"
      [(files)]="files"
      (filesChange)="handleImport($event)"
    >
      <mat-icon fontSet="mdi" fontIcon="mdi-import"></mat-icon>
      <span translate="form.import"></span>
    </button>
    <button mat-button class="with-icon" (click)="handleNew()" *ngIf="model?.id">
      <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
      <span translate="form.new"></span>
    </button>
  </mat-toolbar>
  <div fxFlex fxLayout="column" class="ft-content no-padding">
    <mat-drawer-container class="ft-content-container" fxFlex>
      <mat-drawer mode="side" [opened]="true">
        <mat-toolbar color="primary">
          <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
          </div>
          <h2 translate="form.forms_list"></h2>
          <span fxFlex></span>
        </mat-toolbar>
        <mat-action-list role="list" class="ft-content">
          <div *ngIf="!items || items.length === 0" class="ft-empty-content">
            <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
            <span translate="form.no_element_to_show"></span>
          </div>
          <button mat-list-item *ngFor="let item of items"
                  type="button"
                  (click)="selectItem(item)"
                  [disabled]="item?.id === model?.id"
          >
            <mat-icon
              fontSet="mdi"
              fontIcon="mdi-view-dashboard"
            ></mat-icon>
            <span fxFlex>{{ item?.name }}</span>

            <mat-icon
              *ngIf="item?.id === model?.id"
              fontSet="mdi"
              fontIcon="mdi-pencil-box-outline"
              class="ft-success"
            >
            </mat-icon>
          </button>

        </mat-action-list>
      </mat-drawer>
      <mat-drawer-content fxLayout="column">
        <div fxFlex fxLayout="column" class="ft-content ft-padding">
          <form
            #modelForm="ngForm"
            fxLayout="column"
            fxFlex
            class="ft-padding"
            fxLayoutGap="6px"
            [ngBusy]="busy"
            novalidate
          >
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field fxFlex>
                <input
                  matInput
                  name="name"
                  [(ngModel)]="model.name"
                  [placeholder]="'form.identifier' | translate"
                  required
                  autocomplete="off"
                  minlength="1"
                  tabindex="0"
                  #name="ngModel"
                  ftFieldValidation="{{nameValidationUrl || '/api/form-template/'}}"
                  [query]="{field:'name',pk:model?.id}"
                />
                <mat-error *ngIf="name?.errors?.exists" translate="form.already_exist"></mat-error>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input
                  matInput
                  name="form_label"
                  [(ngModel)]="model.label"
                  [placeholder]="'form.title' | translate"
                  required
                  minlength="1"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
            <div>
              <ng-content></ng-content>
            </div>
            <div>
              <ng-container *ngTemplateOutlet="customTemplateContent; context: {model: model}">
              </ng-container>
            </div>
            <hr class="ft-sep"/>
            <ng-container
              *ngTemplateOutlet="FormBlocks; context: {blocks: model?.blocks}"
            ></ng-container>
          </form>
        </div>
        <div class="ft-actions">
          <button color="warn" mat-mini-fab (click)="deleteModel()" *ngIf="model?.id"
                  [matTooltip]="'form.delete' | translate"
          >
            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
          </button>
          <button color="primary" mat-mini-fab (click)="exportModel()" *ngIf="model?.id"
                  [matTooltip]="'form.export' | translate">
            <mat-icon fontIcon="mdi-export" fontSet="mdi"></mat-icon>
          </button>
          <button
            color="primary"
            mat-mini-fab
            (click)="duplicateModel()"
            *ngIf="model?.id"
            [matTooltip]="'form.duplicate' | translate"
          >
            <mat-icon fontIcon="mdi-content-copy" fontSet="mdi"></mat-icon>
          </button>


          <span fxFlex></span>
          <button
            type="button"
            [disabled]="modelForm.invalid"
            mat-raised-button
            color="primary"
            (click)="save()"
          >
            <span>{{ 'form.save' | translate }}</span>
          </button>
          <!-- <button type="button" mat-raised-button color="warn" (click)="exit()">
               <span>{{ 'form.exit' | translate }}</span>
           </button>-->
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
<ng-template let-blocks="blocks" #FormBlocks>
  <div class="form-setup-blocks" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="12px">

    <div *ngIf="model.category=='PRESCRIPTION_TEMPLATE'"
         class="blocks"
         fxFlex="noshrink"
         fxLayout="column"
         fxLayoutGap="6px"
    >
      <mat-card fxFlex class="block">
        <mat-card-header>
          <mat-card-title>{{ 'settings.consumable_list'|translate }}</mat-card-title>
          <span fxFlex></span>
          <ng-template [ftAcl]="{ resource: 'form-template-consumable', action: 'create' }">
            <button
              color="primary"
              mat-mini-fab
              (click)="AddConsumable()"
              [matTooltip]="'settings.consumable_list' | translate"
            >
              <mat-icon fontIcon="mdi-store-search-outline" fontSet="mdi"></mat-icon>
            </button>
          </ng-template>
        </mat-card-header>
        <mat-card-content fxLayout="column">
          <ft-generic-body-table class="consumable-table"
                                 [remote]="false"
                                 [source]="linesSource$"
                                 [selectable]="false"
                                 [hasSearch]="false"
                                 [hasPagination]="true"
                                 [columns]="linesColumns | async"
                                 [multiActions]="[]"
                                 [pageSize]="5"
          >
          </ft-generic-body-table>
        </mat-card-content>
      </mat-card>
    </div>


    <div
      class="blocks"
      fxFlex="noshrink"
      fxLayout="column"
      fxLayoutGap="6px"
      *ngFor="let b of blocks; trackBy: trackByFn; let blockIdx = index"
    >
      <mat-card fxFlex class="block">
        <mat-card-content fxLayout="column">
          <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field
              [class.mat-form-field-invalid]="checkNameUniqueness(b.name, 'block_name_' + blockIdx, blocks)"
              fxFlex>
              <input
                matInput
                name="{{ 'block_name_' + blockIdx }}"
                [(ngModel)]="b.name"
                minlength="1"
                autocomplete="off"
                [placeholder]="'form.identifier' | translate"
                required
              />
              <mat-hint class="ft-warning"
                        *ngIf="checkNameUniqueness(b.name, 'block_name_' + blockIdx, blocks)">{{ "form.name_not_unique"| translate }}
              </mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                matInput
                [(ngModel)]="b.label"
                [placeholder]="'form.block_label' | translate"
                minlength="1"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <mat-form-field fxFlex>
            <input
              matInput
              [(ngModel)]="b.config.display_rule"
              [placeholder]="'form.display_rule'|translate"
            />
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
          <button
            mat-icon-button
            color="{{b.config.classes['layout-column'] ? 'primary' :null}}"
            (click)="
                            b.config.classes['layout-column'] = !b.config.classes['layout-column'];
                            b.config.classes['layout-row'] = false;
                            b.config.classes['layout-wrap'] = false
                        "
            [matTooltip]="'form.form_section_layout_column' | translate"
            aria-label="drag-list"
          >
            <mat-icon fontIcon="mdi-drag-vertical" fontSet="mdi"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="{{b.config.classes['layout-row'] ? 'primary' :null}}"
            (click)="
                            b.config.classes['layout-row'] = !b.config.classes['layout-row'];
                            b.config.classes['layout-column'] = false
                        "
            [matTooltip]="'form.form_section_layout_row' | translate"
          >
            <mat-icon fontIcon=" mdi-drag-horizontal" fontSet="mdi"></mat-icon>
          </button>
          <button
            mat-icon-button color="{{ b.config.classes['layout-wrap']?'primary':'accent'}}"
            (click)="b.config.classes['layout-wrap'] = !b.config.classes['layout-wrap']"
            [disabled]="!b.config.classes['layout-row']"
            [matTooltip]="'form.form_section_layout_wrap' | translate"
          >
            <mat-icon fontIcon="mdi-wrap" fontSet="mdi"></mat-icon>
          </button>
          <span fxFlex></span>
          <button
            *ngIf="hiddenBlocks['block_' + blockIdx] && b.fields && b.fields.length > 0"
            mat-icon-button
            color="primary"
            (click)="hiddenBlocks['block_' + blockIdx] = false"
            [matTooltip]="'form.expand' | translate"
          >
            <mat-icon fontIcon="mdi-arrow-expand-all" fontSet="mdi"></mat-icon>
          </button>
          <button
            *ngIf="
                            !hiddenBlocks['block_' + blockIdx] && b.fields && b.fields.length > 0
                        "
            mat-icon-button
            color="primary"
            (click)="hiddenBlocks['block_' + blockIdx] = true"
            [matTooltip]="'form.collapse' | translate"
          >
            <mat-icon fontIcon="mdi-arrow-collapse-all" fontSet="mdi"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="duplicateBlock(b, blockIdx)"
            [matTooltip]="'form.duplicate' | translate"
          >
            <mat-icon fontIcon="mdi-content-copy" fontSet="mdi"></mat-icon>
          </button>
          <button mat-icon-button color="primary" [matMenuTriggerFor]="blockMenu">
            <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
          </button>

          <mat-menu #blockMenu="matMenu" class="ft-small-menu">
            <button mat-menu-item (click)="newField(b)">
              <mat-icon
                fontSet="mdi"
                fontIcon="mdi-card-plus"
                color="primary"
              ></mat-icon>
              <span translate="form.add_field"></span>
            </button>
            <button
              mat-menu-item
              accept="image/*"
              ngfSelect
              multiple="1"
              [(files)]="files"
              (filesChange)="addPictureField(b, $event)"
            >
              <mat-icon
                fontIcon="mdi-image-plus"
                fontSet="mdi"
                color="primary"
              ></mat-icon>
              <span translate="form.add_image"></span>
            </button>
            <button mat-menu-item (click)="newBlock(b)" aria-label="edit">
              <mat-icon
                fontIcon="mdi-card-plus-outline"
                color="primary"
                fontSet="mdi"
              ></mat-icon>
              <span translate="form.add_block"></span>
            </button>
            <button
              *ngIf="this.model.blocks.length > 1"
              mat-menu-item
              (click)="removeBlock(blockIdx)"
              aria-label="edit event"
            >
              <mat-icon fontIcon="mdi-delete" fontSet="mdi" color="warn"></mat-icon>
              <span translate="form.delete"></span>
            </button>
          </mat-menu>
        </mat-card-actions>
      </mat-card>
      <div
        *ngIf="!hiddenBlocks['block_' + blockIdx]"
        fxFlex
        cdkDropList
        (cdkDropListDropped)="drop(b, $event)"
      >
        <mat-card
          class="block-field"
          *ngFor="let f of b['fields']; trackBy: trackByFn; let fieldIdx = index"
          cdkDrag
        >
          <div class="form-block-header" fxLayoutAlign="center">
            <button mat-icon-button aria-label="drag-list">
              <mat-icon fontIcon="mdi-drag-horizontal" fontSet="mdi"></mat-icon>
            </button>
          </div>
          <mat-card-content fxLayout="column wrap">
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field [class.mat-form-field-invalid]="checkNameUniqueness(
                                            f.name,
                                            'field_name_' + fieldIdx,
                                            b.fields
                                        )" fxFlex>
                <input
                  matInput
                  [placeholder]="'form.identifier' | translate"
                  name="{{ 'field_name_' + fieldIdx }}"
                  [(ngModel)]="f.name"
                  minlength="1"
                  (change)="
                                        checkNameUniqueness(
                                            f.name,
                                            'field_name_' + fieldIdx,
                                            b.fields
                                        )
                                    "
                />
                <mat-hint class="ft-warning"
                          *ngIf="checkNameUniqueness(
                                            f.name,
                                            'field_name_' + fieldIdx,
                                            b.fields
                                        )"
                >{{ "form.name_not_unique"| translate }}
                </mat-hint>
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>{{ 'form.type' | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="f.type"
                  [compareWith]="compareCondition"
                  (ngModelChange)="fieldTypeChange(f); displayedFields['block_' + blockIdx+'_field_'+fieldIdx]=true;"
                >
                  <mat-option *ngFor="let t of fieldTypes" [value]="t">
                    {{ 'form.' + t | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="displayedFields['block_' + blockIdx+'_field_'+fieldIdx]">
              <br>
              <hr class="ft-sep"/>
              <br>
              <mat-accordion class="field-rules" multi>
                <mat-expansion-panel expanded="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4>{{ "form.details"|translate }}</h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="column wrap">
                    <div fxLayout="row" fxLayoutGap="6px">
                      <mat-form-field fxFlex="50">
                        <input
                          matInput
                          [(ngModel)]="f.label"
                          [placeholder]="'form.field_label' | translate"
                          autocomplete="off"
                        />
                      </mat-form-field>
                      <mat-form-field fxFlex *ngIf="f.type === 'number'">
                        <input
                          matInput
                          [(ngModel)]="f.options['suffix']"
                          [placeholder]="'form.suffix' | translate"
                        />
                      </mat-form-field>
                      <mat-form-field *ngIf="f.type === 'number'" fxFlex>
                        <input
                          matInput
                          [(ngModel)]="f.options['decimals']"
                          type="number"
                          [placeholder]="'form.decimals' | translate"
                        />
                      </mat-form-field>
                      <mat-form-field
                        fxFlex
                        *ngIf="
                                    f.options.is_measurement &&
                                    ['date', 'text', 'long-text', 'number'].includes(f.type)
                                "
                      >
                        <label translate="form.measurements"></label>
                        <mat-select
                          [(ngModel)]="f.options.measurement"
                          [compareWith]="compareCondition"
                        >
                          <mat-option
                            *ngFor="let m of measurements; trackBy: trackByFn"
                            [value]="m"
                          >
                            {{ m.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" *ngIf="f.type === 'number'">
                      <mat-form-field fxFlex="50">
                        <input
                          matInput
                          [(ngModel)]="f.value"
                          type="number"
                          [placeholder]="'form.default_value' | translate"
                        />
                      </mat-form-field>
                    </div>
                    <div
                      fxLayout="column"
                      fxFlex
                      *ngIf="['checkbox', 'list', 'radio-button'].includes(f.type)"
                    >
                      <mat-form-field
                        floatLabel="never"
                        *ngFor="let o of f['options']; trackBy: trackByFn; let oIdx = index"
                      >
                        <input
                          matInput
                          [ngModel]="o"
                          (ngModelChange)="optionChanged(f['options'], $event, oIdx)"
                        />
                        <button
                          mat-icon-button
                          (click)="removeOption(f, oIdx)"
                          matSuffix
                          color="warn"
                        >
                          <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                        </button>
                      </mat-form-field>

                      <mat-form-field floatLabel="never">
                        <input
                          matInput
                          (click)="addOption(f)"
                          minlength="1"
                          readonly
                          [placeholder]="'form.add_option' | translate"
                        />
                      </mat-form-field>
                    </div>
                    <div fxLayout="column" fxFlex *ngIf="f.type === 'table-layout'">
                      <hr class="ft-sep"/>
                      <div fxLayout="column" fxFlex>
                        <mat-form-field
                          floatLabel="never"
                          *ngFor="
                                        let c of f.options['columns'];
                                        trackBy: trackByFn;
                                        let cIdx = index
                                    "
                        >
                          <input
                            matInput
                            [ngModel]="c"
                            minlength="1"
                            (ngModelChange)="
                                            optionChanged(f.options['columns'], $event, cIdx)
                                        "
                          />
                          <button
                            matSuffix
                            mat-icon-button
                            color="warn"
                            (click)="removeOptionColumn(f.options['columns'], cIdx)"
                            aria-label="cancel"
                          >
                            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                          </button>
                        </mat-form-field>
                        <mat-form-field floatLabel="never">
                          <input
                            matInput
                            (click)="addOption(f, 'columns')"
                            minlength="1"
                            readonly
                            [placeholder]="'form.add_header' | translate"
                          />
                        </mat-form-field>
                      </div>
                      <hr class="ft-sep"/>
                      <div fxLayout="column" fxFlex>
                        <div
                          fxLayout="row"
                          *ngFor="
                                        let r of f.options['rows'];
                                        trackBy: trackByFn;
                                        let rIdx = index
                                    "
                        >
                          <fieldset class="rows-field-set" fxFlex fxLayout="column">
                            <legend>L_{{ rIdx + 1 }}</legend>
                            <div
                              fxLayout="row"
                              *ngFor=" let e of r['items'];  trackBy: trackByFn;  let iIdx = index; "
                              fxFlex
                              fxLayoutGap="6px"
                            >
                              <mat-form-field fxFlex="nogrow">
                                <mat-label translate="form.type"></mat-label>
                                <mat-select
                                  [(ngModel)]="e.type"
                                  (ngModelChange)="fieldTypeChange(e)"
                                >
                                  <mat-option
                                    *ngFor=" let t of ['radio-button','text','number','paragraph','an_empty_field'];"
                                    [value]="t"
                                  >
                                    <span>{{ 'form.' + t | translate }}</span>
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field fxFlex>
                                <input
                                  matInput
                                  [(ngModel)]="e.label"
                                  [placeholder]="'form.field_label' | translate"
                                />
                              </mat-form-field>

                              <mat-form-field *ngIf="e.type === 'number'" fxFlex>
                                <input
                                  matInput
                                  [(ngModel)]="e.options['decimals']"
                                  type="number"
                                  [placeholder]="'form.decimals' | translate"
                                />
                              </mat-form-field>
                              <mat-form-field fxFlex *ngIf="e.type === 'text'">
                                <input
                                  matInput
                                  [(ngModel)]="e.options['suffix']"
                                  [placeholder]="'form.suffix' | translate"
                                />
                                <button
                                  mat-icon-button
                                  matSuffix
                                  color="warn"
                                  (click)="removeItem(r['items'], iIdx)"
                                >
                                  <mat-icon
                                    fontIcon="mdi-close"
                                    fontSet="mdi"
                                  ></mat-icon>
                                </button>
                              </mat-form-field>
                            </div>
                            <mat-form-field floatLabel="never">
                              <input
                                matInput
                                (click)="addRowElement(r)"
                                minlength="1"
                                readonly
                                [placeholder]="'form.add_item' | translate"
                              />
                            </mat-form-field>
                          </fieldset>
                          <div
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            fxFlex="nogrow"
                          >
                            <button
                              mat-icon-button
                              color="warn"
                              (click)="removeItem(f.options['rows'], rIdx)"
                            >
                              <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                            </button>
                          </div>
                        </div>
                        <mat-form-field floatLabel="never">
                          <input
                            matInput
                            (click)="addTableLayoutRow(f)"
                            minlength="1"
                            readonly
                            [placeholder]="'form.add_row' | translate"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" *ngIf="f.type === 'image' || f.picture">
                      <hr class="ft-sep"/>
                      <div fxFlex fxLayout="row" fxLayoutGap="6px">
                        <mat-form-field>
                          <input
                            matInput
                            [(ngModel)]="f.config.picture_style.height"
                            [placeholder]="'form.height' | translate"
                          />
                        </mat-form-field>
                        <mat-form-field>
                          <input
                            matInput
                            [(ngModel)]="f.config.picture_style.width"
                            [placeholder]="'form.width' | translate"
                          />
                        </mat-form-field>
                        <mat-form-field>
                          <mat-label translate="form.position"></mat-label>
                          <mat-select
                            [(ngModel)]="f.config.picture_style['margin-left']"
                            [compareWith]="compareCondition"
                          >
                            <mat-option *ngFor="let t of ['unset', 'auto']" [value]="t">
                              <span>{{ 'form.' + t | translate }}</span>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div
                        fxFlex="nogrow"
                        [ngClass]="{
                                    'empty-picture-field': !f.picture,
                                    'picture-field': !!f.picture
                                }"
                        ngfDrop
                        accept="image/*"
                        ngfSelect
                        multiple="1"
                        [(files)]="files"
                        (filesChange)="editPictureField(f, $event)"
                      >
                        <div
                          class="upload-box-label"
                          fxFlex
                          fxLayoutAlign="center center"
                          fxLayout="column"
                          *ngIf="!f.picture"
                        >
                          <span translate="form.drag_and_drop_or_click"></span>
                          <mat-icon fontIcon="mdi-cloud-upload" fontSet="mdi"></mat-icon>
                        </div>

                        <div fxLayoutAlign="center center">
                          <img [src]="f.picture" *ngIf="f.picture"/>
                          <button
                            matSuffix
                            *ngIf="f.picture"
                            color="warn"
                            mat-icon-button
                            class="remove-picture"
                            (click)="resetPicture(f, $event)"
                          >
                            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row" fxFlex *ngIf="f.type === 'formula'">
                      <mat-form-field fxFlex>
                        <input
                          matInput
                          [(ngModel)]="f.options.formula"
                          [placeholder]="'form.base_formula' | translate"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4>Style</h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="column">
                    <mat-form-field fxFlex>
                      <input
                        matInput
                        [(ngModel)]="f.config.classes"
                        placeholder="Css-Classes"
                      />
                    </mat-form-field>
                    <mat-form-field fxFlex>
                                        <textarea
                                          matInput
                                          [(ngModel)]="f.config.cssStyle"
                                          placeholder="Css"
                                          [rows]="5"
                                        ></textarea>
                    </mat-form-field>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="displayRulesExpanded=true"
                                     (closed)="displayRulesExpanded=false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4>{{ "form.display_rule"|translate }}</h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-form-field fxFlex>
                    <textarea
                      matInput
                      [(ngModel)]="f.config.display_rule"
                      placeholder="Condition"
                      [rows]="5"
                    ></textarea>
                  </mat-form-field>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="['checkbox','radio-button','list','date'].includes(f.type)"
                                     (opened)="valueRulesExpanded=true"
                                     (closed)="valueRulesExpanded=false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4>{{ "form.value_rules"|translate }}</h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngIf="!f?.config?.value_rules ||  f?.config?.value_rules.length==0"
                       class="ft-empty-content"
                       fxLayoutAlign="center">
                    <span translate="form.no_element_to_show"></span>
                  </div>
                  <div>
                    <ng-container *ngFor="let vr of f?.config?.value_rules ; let vIdx = index"
                                  [ngSwitch]="f.type">
                      <div fxLayout="row" fxLayoutGap="6px">
                        <mat-form-field fxFlex>
                          <input required
                                 matInput
                                 [(ngModel)]="vr.condition"
                                 [placeholder]="'form.condition' | translate"
                          />
                          <button
                            mat-icon-button
                            (click)="removeOptionRow(f?.config?.value_rules, vIdx)"
                            matSuffix
                            color="warn"
                          >
                            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                          </button>
                        </mat-form-field>
                        <ng-template [ngSwitchCase]="'checkbox'">
                          <div fxFlex fxLayout="column">
                            <span>{{ 'form.value'|translate }}</span>
                            <div fxFlex fxLayout="row" fxLayoutGap="8px">
                              <ng-container
                                *ngFor="let o of f['options']; trackBy: trackByFn; let idx = index"
                              >
                                <mat-checkbox
                                  color="primary"
                                  [(ngModel)]="vr?.value[o]"
                                >
                                  <span>{{ o }}</span>
                                </mat-checkbox>
                              </ng-container>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="'radio-button'">
                          <mat-form-field fxFlex floatLabel="always" class="ft-radio-field">
                            <input matInput [placeholder]="f.label" style="display: none"
                                   [placeholder]="'form.value'|translate"/>
                            <mat-radio-group
                              [(ngModel)]="vr.value"
                              [fxLayout]="'row'"
                            >
                              <mat-radio-button *ngFor="let o of f.options" [value]="o">
                                {{ o }}
                              </mat-radio-button>
                            </mat-radio-group>
                          </mat-form-field>
                        </ng-template>
                        <mat-form-field fxFlex *ngSwitchCase="'list'">
                          <mat-label>{{ 'form.value'|translate }}</mat-label>
                          <mat-select
                            [(ngModel)]="vr.value"
                          >
                            <mat-option *ngFor="let o of f.options" [value]="o">
                              {{ o }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <ng-template [ngSwitchCase]="'date'">
                          <div fxLayout="row" fxFlex>
                            <mat-form-field class="mn-datepicker-container">
                              <input
                                matInput
                                [placeholder]="'form.value'|translate"
                                [matDatepicker]="f_date"
                                [(ngModel)]="f.value"
                              />
                              <mat-datepicker-toggle matSuffix [for]="f_date">
                                <mat-icon
                                  matDatepickerToggleIcon
                                  fontSet="mdi"
                                  fontIcon="mdi-calendar-today"
                                ></mat-icon>
                              </mat-datepicker-toggle>
                              <mat-datepicker #f_date></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </ng-template>
                      </div>
                    </ng-container>
                  </div>
                  <div>
                    <span fxFlex></span>
                    <button mat-mini-fab
                            color="primary"
                            [matTooltip]="'form.add' | translate"
                            (click)="addValueRule(f,$event)"
                    >
                      <mat-icon fontIcon="mdi-playlist-plus" fontSet="mdi"></mat-icon>
                    </button>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-card-content>
          <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
            <span fxFlex></span>
            <mat-slide-toggle
              *ngIf="f.type !== 'table-layout'"
              color="primary"
              fxFlex="nogrow"
              [(ngModel)]="f.required"
            >
              <span translate="form.required"></span>
            </mat-slide-toggle>
            <mat-slide-toggle
              *ngIf="f.type === 'table-layout'"
              color="primary"
              fxFlex="nogrow"
              [(ngModel)]="f.options.has_border"
            >
              <span translate="form.has_border"></span>
            </mat-slide-toggle>
            <button
              mat-icon-button
              color="primary"
              (click)="duplicateField(b, f, fieldIdx)"
              [matTooltip]="'form.duplicate' | translate"
            >
              <mat-icon fontIcon="mdi-content-copy" fontSet="mdi"></mat-icon>
            </button>
            <button
              *ngIf="
                                !f.picture &&
                                !['table-layout', 'image'].includes(f.type)
                            "
              mat-icon-button
              color="accent"
              accept="image/*"
              ngfSelect
              multiple="1"
              [(files)]="files"
              (filesChange)="addFieldImage(b, f, fieldIdx, $event)"
              [matTooltip]="'form.add_image' | translate"
            >
              <mat-icon fontIcon="mdi-image-plus" fontSet="mdi"></mat-icon>
            </button>
            <button *ngIf="!displayedFields['block_' + blockIdx+'_field_'+fieldIdx]"
                    (click)="displayedFields['block_' + blockIdx+'_field_'+fieldIdx] = true"
                    mat-icon-button
                    color="primary"
                    [matTooltip]="'form.expand' | translate"
            >
              <mat-icon fontIcon="mdi-arrow-expand-all" fontSet="mdi"></mat-icon>
            </button>
            <button *ngIf="displayedFields['block_' + blockIdx+'_field_'+fieldIdx]"
                    (click)="displayedFields['block_' + blockIdx+'_field_'+fieldIdx] = false"
                    mat-icon-button
                    color="primary"
                    [matTooltip]="'form.collapse' | translate"
            >
              <mat-icon fontIcon="mdi-arrow-collapse-all" fontSet="mdi"></mat-icon>
            </button>
            <button mat-icon-button color="primary" [matMenuTriggerFor]="fieldMenu">
              <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
            </button>
            <mat-menu #fieldMenu="matMenu" class="ft-small-menu">
              <button mat-menu-item (click)="newField(b, fieldIdx)">
                <mat-icon
                  fontIcon="mdi-card-plus"
                  fontSet="mdi"
                  color="primary"
                ></mat-icon>
                <span translate="form.add_field"></span>
              </button>
              <button mat-menu-item (click)="newBlock(b)">
                <mat-icon
                  fontIcon="mdi-card-plus-outline"
                  color="primary"
                  fontSet="mdi"
                ></mat-icon>
                <span translate="form.add_block"></span>
              </button>
              <button
                mat-menu-item
                accept="'image/*'"
                ngfSelect
                multiple="1"
                [(files)]="files"
                (filesChange)="addPictureField(b, $event, fieldIdx)"
              >
                <mat-icon
                  fontIcon="mdi-image-plus"
                  color="primary"
                  fontSet="mdi"
                ></mat-icon>
                <span translate="form.add_image"></span>
              </button>
              <button mat-menu-item (click)="removeItem(b['fields'], fieldIdx)">
                <mat-icon
                  fontIcon="mdi-delete"
                  fontSet="mdi"
                  color="warn"
                ></mat-icon>
                <span translate="form.delete"></span>
              </button>
            </mat-menu>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</ng-template>
