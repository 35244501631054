<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-store-search-outline"></mat-icon>
    </button>
  </div>
  <h2>{{ 'settings.consumable_list' | translate }}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">
  <div [ngBusy]="subscription"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container" style="min-height:400px">
    <div fxLayout="column" fxFlex fxFill>
      <ft-basic-generic-view-table fxFlex style="min-height:400px"  class="prescription-consumable-table"
                                   [remote]="true"
                                   [source]="source$"
                                   [hasSearch]="true"
                                   [selectable]="true"
                                   [hasPagination]="true"
                                   [columns]="displayedColumns"
                                   [multiActions]="[]"
                                   (searchChange)="handleSearchChange($event)"
                                   [pageSize]="pageSize"
                                   [pageSizeOptions]="pageSizeOptions"
                                   [paginateHandler]="paginateHandler"
                                   [(selected)]="selected"
      >
      </ft-basic-generic-view-table>


    </div>
  </div>

  <div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" mat-raised-button color="primary" (click)="handleSelection()"
            [disabled]="selected && selected.length==0">
      <span>{{ 'shared.add'|translate }}</span>
    </button>
    <button type="button" mat-raised-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{ 'shared.reset'|translate }}</span>
    </button>
  </div>
</form>
